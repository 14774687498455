import React from "react";
import classes from "./../components.module.scss";

import IconWrapper from "./../IconWrapper";
import { ReactComponent as PenIcon } from "./../../Assets/pen.svg";
import { useHistory } from "react-router-dom";

import convertDateToSpecificFormat from "./../../Helper/convertDateToSpecificFormat";
import Button from "../../Utils/Button";

const OpinonCard = ({
  className = "",
  varient = "single",
  scoutsOpinion: scoutsOpinionLink,
  docketNumber,
  dateOfPublish,
  docId,
  defendant,
  plantiff,
  termYear,
}) => {
  const history = useHistory();

  const onComposeClick = () => {
    history.push("/compose-post", {
      scoutsOpinionLink,
      docketNumber,
      dateOfPublish,
      docId,
      defendant,
      plantiff,
      termYear,
    });
  };

  return (
    <div
      className={`${classes.opinonCardWrapper} ${className} ${
        varient === "single" && classes["opinonCardWrapper-single"]
      }`}
    >
      <p className={classes["vs-txt"]}>
        {plantiff}
        <span> vs </span> {defendant}
      </p>
      <Button
        style={{
          backgroundColor: "transparent",
          color: "var(--blue-d)",
          width: "auto",
          padding: "0px 12px",
          height: "38px",
          textAlign: "center",
          margin: "12px",
          border: "solid 2px var(--color-l)",
        }}
        onClick={() => window.open(scoutsOpinionLink)}
      >
        {/* <a
          rel={"external"}
          target="__blank"
          href={`http://${scoutsOpinionLink}`}
          rel="noreferrer"
        >
        </a> */}
        Read Full Opinion Here
      </Button>
      <p>Orig. Pub. {convertDateToSpecificFormat(dateOfPublish)}</p>
      <p>Docket# {docketNumber}</p>
      <p>Term Year# {termYear}</p>
      {varient === "single" && (
        // <div className={classes["composeBtn"]}>
        <IconWrapper
          onClick={onComposeClick}
          icon={PenIcon}
          className={classes["composeBtn"]}
        />
        // </div>
      )}
    </div>
  );
};

export default OpinonCard;
