import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "./scrollToTop.scss";
import { ReactComponent as ArrowTopIcon } from "./angle-up.svg";
import { motion, AnimatePresence } from "framer-motion";
const Index = () => {
  const wrapperID = "scrollToTop-1dfcvt43bcvgsdr3";
  const [showScroll, setShowScroll] = useState(false);
  const [isContainerExist, setContainerExist] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 60) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 60) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return createPortal(
    <AnimatePresence>
      <motion.div
        id={wrapperID}
        initial={{ opacity: 0 }}
        animate={{ opacity: showScroll ? 1 : 0, scale: showScroll ? 1 : 0 }}
        transition={{ duration: 0 }}
      >
        <span onClick={scrollTop}>
          <ArrowTopIcon />
        </span>
      </motion.div>
    </AnimatePresence>,
    document.body
  );
};

export default Index;
