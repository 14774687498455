import { Main as MainLayout, Minimal as MinimalLayout } from "./Layout";
import {
  ChangePasswordView,
  PublicHomeView,
  SessionView,
  ProfileView,
  FeedsView,
  OpninionsView,
  InstitutionView,
  EditProfileView,
  TwitterAuthView,
  ComposePostView,
  BriefcaseView,
  ForgetPasswordView,
  SinglePostView,
} from "./Views";

export const routesList = [
  {
    id: "changepw",
    path: "/change-password",
    component: ChangePasswordView,
    layout: MainLayout,
  },
  {
    id: "private-profile",
    path: "/ss/:uname",
    component: ProfileView,
    layout: MainLayout,
  },
  {
    id: "p-feeds",
    path: "/feeds",
    component: FeedsView,
    layout: MainLayout,
  },
  {
    id: "opinon",
    path: "/opinions",
    component: OpninionsView,
    layout: MainLayout,
  },
  {
    id: "compose-post",
    path: "/compose-post",
    component: ComposePostView,
    layout: MainLayout,
  },
  {
    id: "briefcase-post",
    path: "/briefcase",
    component: BriefcaseView,
    layout: MainLayout,
  },
  {
    id: "institution",
    path: "/edu/:instituionSlug",
    component: InstitutionView,
    layout: MainLayout,
  },
  {
    id: "public-institution",
    path: "/edu/:instituionSlug",
    component: InstitutionView,
    layout: MinimalLayout,
  },
  {
    id: "edit-profile",
    path: "/edit-profile",
    component: EditProfileView,
    layout: MainLayout,
  },
  {
    id: "single-post-private",
    path: "/post/:postId",
    component: SinglePostView,
    layout: MainLayout,
  },
  {
    id: "single-post-public",
    path: "/post/:postId",
    component: SinglePostView,
    layout: MinimalLayout,
  },
  {
    id: "public-home",
    path: "/",
    component: PublicHomeView,
    layout: MinimalLayout,
    setting: {
      header: false,
    },
  },
  {
    id: "login",
    path: "/s/login",
    component: SessionView,
    layout: MinimalLayout,
  },
  {
    id: "forgetPassword",
    path: "/forget",
    component: ForgetPasswordView,
    layout: MinimalLayout,
  },
  {
    id: "signup",
    path: "/s/signup",
    component: SessionView,
    layout: MinimalLayout,
  },
  {
    id: "public-profile",
    path: "/ss/:uname",
    component: ProfileView,
    layout: MinimalLayout,
  },
  {
    id: "twitter-auth",
    path: "/twitter-auth",
    component: TwitterAuthView,
    layout: MinimalLayout,
    setting: {
      header: false,
    },
  },
];

export const publicRoutesId = [
  "public-home",
  "login",
  "signup",
  "public-profile",
  "twitter-auth",
  "public-institution",
  "forgetPassword",
  "single-post-public",
];
export const userRoutesId = [
  "single-post-private",
  "private-profile",
  "changepw",
  "p-feeds",
  "opinon",
  "edit-profile",
  "institution",
  "compose-post",
  "briefcase-post",
];
export const adminRoutesId = [];
