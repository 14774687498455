import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import axiosInstance from "./../../../axiosInstance";

import { useAuthContext } from "../../../Context/AuthContext";
import classes from "./../sessions.module.scss";
import AddonInputField from "./../../../Utils/AddonInputField";
import SubmissionButtonsGroup from "./SubmissionButtonsGroup";
import { ReactComponent as UserIcon } from "./../../../Assets/user.svg";
import { ReactComponent as LockIcon } from "./../../../Assets/lock.svg";
import { UpdateUsername, InstitutionSelector } from "./../../../Components";

const signupSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  email: yup
    .string()
    .email("Invalid Email")
    // .matches(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/, "Invalid Email")
    .required("Email must not be empty"),
  password: yup
    .string()
    .min(6, "New Password must be at least 6 characters")
    .required("New Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  institutionName: yup.string().required("Please Select Institution"),
  institutionUsername: yup.string().required("Please Select Institution"),
  lawSchool: yup.string().required("Please Select Institution"),
  legalPosition: yup.string().required("Please Select legal Position"),
});

const SignupForm = (props) => {
  const { signup, setAuthState } = useAuthContext();
  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(signupSchema),
  });
  const [username, setUsername] = useState("");
  const [isValidUsername, setIsValidUsername] = useState(false);

  const [
    showInstitutionSelectorModal,
    setShowInstitutionSelectorModal,
  ] = useState(false);

  // # COMPONENT FUNCTIONS.
  const onSubmitHandler = async (data) => {
    try {
      if (!isValidUsername) {
        props.setErrorMsg("Please select Valid Username");
        return;
      }
      props.setErrorMsg(null);
      props.setLoading(true);
      const resData = await signup({ userName: username, ...data });
      if (resData) {
        props.setLoading(false);
        props.setSuccessMsg("Successfully Signed up");
        setTimeout(() => {
          setAuthState({
            isAuthenticated: true,
            expiresAt: resData.exp,
            userInfo: resData,
            role: resData.role,
          });
          props.history.push(`/ss/${resData.userName}`);
        }, 1000);
      }
    } catch (err) {
      props.setLoading(false);
      err.response?.data?.error?.message &&
        props.setErrorMsg(`${err.response?.data?.error?.message}`.trim());
    }
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value?.toLowerCase());
  };

  const selectorModalHandler = () => setShowInstitutionSelectorModal((p) => !p);

  const onSelectInstitution = ({
    institutionName,
    institutionUsername,
    id,
  }) => {
    setValue("institutionName", institutionName, { shouldValidate: true });
    setValue("institutionUsername", institutionUsername);
    setValue("lawSchool", id);
    selectorModalHandler();
  };

  return (
    <Fragment>
      <InstitutionSelector
        isOpen={showInstitutionSelectorModal}
        onClosed={selectorModalHandler}
        onSelectHandler={onSelectInstitution}
      />
      <div className={classes.fieldsWrapper}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <UpdateUsername
            userName={username}
            onChange={onUsernameChange}
            varient="CHECK_VALIDATION"
            setIsValidUsername={setIsValidUsername}
            leftIcon={UserIcon}
          />
          <AddonInputField
            ref={register}
            name="firstName"
            leftIcon={UserIcon}
            placeholder="First Name"
            required
            error={!!errors.firstName}
            errorText={errors.firstName?.message}
          />
          <AddonInputField
            ref={register}
            name="lastName"
            leftIcon={UserIcon}
            placeholder="Last Name"
            required
            error={!!errors.lastName}
            errorText={errors.lastName?.message}
          />
          <label className={classes.dropDown}>
            <span>Select Legal Position</span>
            <select ref={register} name="legalPosition">
              <option value="JD Candidate"> JD Candidate</option>
              <option value="LLM Candidate">LLM Candidate</option>
              <option value="Educational Faculty">Educational Faculty</option>
              <option value="Practicing Attorney">Practicing Attorney</option>
            </select>
          </label>
          <AddonInputField
            ref={register}
            name="institutionName" //THIS IS THE INSTITUTION ID.
            leftIcon={UserIcon}
            placeholder="Select Institution"
            required
            onClick={selectorModalHandler}
            error={!!errors.institutionName}
            errorText={errors.institutionName?.message}
            // readOnly={true}
          />
          <AddonInputField
            containerStyle={{ display: "none" }}
            ref={register}
            name="institutionUsername" //THIS IS THE INSTITUTION ID.
            leftIcon={UserIcon}
            placeholder="Select Institution"
            required
            error={!!errors.lawSchool}
            errorText={errors.lawSchool?.message}
            readOnly={true}
          />
          <AddonInputField
            containerStyle={{ display: "none" }}
            ref={register}
            name="lawSchool" //THIS IS THE INSTITUTION ID.
            leftIcon={UserIcon}
            placeholder="Select Institution"
            required
            error={!!errors.lawSchool}
            errorText={errors.lawSchool?.message}
            readOnly={true}
          />
          <AddonInputField
            ref={register}
            name="email"
            leftIcon={UserIcon}
            placeholder="Email"
            required
            error={!!errors.email}
            errorText={errors.email?.message}
          />
          <AddonInputField
            ref={register}
            name="password"
            leftIcon={LockIcon}
            placeholder="Password"
            type="password"
            required
            error={!!errors.password}
            errorText={errors.password?.message}
          />
          <AddonInputField
            ref={register}
            name="confirmPassword"
            leftIcon={LockIcon}
            placeholder="Confirm Password"
            type="password"
            required
            error={!!errors.confirmPassword}
            errorText={errors.confirmPassword?.message}
          />
          <SubmissionButtonsGroup
            submitBtnLabel="SIGNUP"
            setLoading={props.setLoading}
            onTwitterClick={props.onTwitterHandler}
          />
        </form>
      </div>
    </Fragment>
  );
};

export default SignupForm;
