import React, { useState, useEffect, Fragment } from "react";
import classes from "./profile.module.scss";
import PostFeed from "../../Components/Post";
import ErrorModal from "../../Components/ErrorModal";
import axiosInstance from "./../../axiosInstance";
import { useAuthContext } from "../../Context/AuthContext";
import { AlertBox, Spinner } from "../../Utils";

// #PROFILE COMPONENTS HERE.
const SinglePostPublic = (props) => {
  const { authState } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState({});
  const [showMsgModal, setShowMsgModal] = useState(null);
  const {
    params: { postId },
  } = props.match;
  useEffect(() => {
    //console.log("--img", uname);
    getPostById(postId);
    // return () => {};
  }, [postId]);

  const getPostById = async (username = null) => {
    try {
      if (!postId) {
        props.history.push("/not-found");
        return;
      }
      const res = await axiosInstance.get(`/user/post/${postId}`);
      const { postObj } = res.data;
      console.log(postObj);
      setPostData(postObj);
      setLoading(false);
    } catch (err) {
      console.log(err, err.response);
      setShowMsgModal("Post Not Found.");
    }
  };

  const clearError = () => {
    setShowMsgModal(null);
    props.history.push({
      pathname: "/not-found",
      state: { msg: "Post Not Found" },
    });
  };
  return (
    <Fragment>
      <ErrorModal error={showMsgModal} onClear={clearError} />
      <div className={classes.profileWrapper} style={{ alignItems: "center" }}>
        {loading ? (
          <Spinner />
        ) : postData ? (
          <PostFeed isSinglePost={true} {...postData} />
        ) : (
          <AlertBox varient="danger" msg="Post Not Found" />
        )}
      </div>
    </Fragment>
  );
};

export default SinglePostPublic;
