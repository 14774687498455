import React from "react";

import classes from "./icon.module.scss";
import { motion } from "framer-motion";

const IconWrapper = ({
  className,
  icon: Icon,
  styleIcon,
  isActive = true,
  ...restProps
}) => {
  return (
    <motion.div
      className={`${classes.iconWrapper} ${className}`}
      {...restProps}
      whileTap={{ scale: 0.8 }}
      transition={{ duration: 0, type: "tween" }}
      style={{ filter: `grayscale(${isActive ? 0 : 1})` }}
    >
      <Icon style={styleIcon} />
    </motion.div>
  );
};

export default IconWrapper;
