import React, { useState } from "react";
import { Link } from "react-router-dom";

import classes from "./../minimalLayout.module.scss";
import Logo from "../../../Components/Logo";

import { useUiSettings } from "./../../../Context/UiContext";
const Header = (props) => {
  const { sidebarOpenToggler } = useUiSettings();

  const [isSearchBarOpen, setSearchBarOpen] = useState(false);

  const searchBarToggler = () => {
    setSearchBarOpen((p) => !p);
  };

  return (
    <header className={classes.header}>
      <Link to="/">
        <Logo className={classes.logo} />
      </Link>
    </header>
  );
};

export default Header;
