import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "../../Utils/Button";
import classes from "./forget.module.css";
import AddonInputField from "./../../Utils/AddonInputField";
import { ReactComponent as UserIcon } from "./../../Assets/user.svg";
import LoadingWrapper from "../../Utils/Spinner/index";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import axios from "../../axiosInstance";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    // .matches(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/, "Invalid Email")
    .required("Email must not be empty"),
});

const LoginForm = (props) => {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const onSubmitHandler = async (data) => {
    try {
      setError(null);
      setLoading(true);
      await axios.post("/user/resetPassword", data);
      setError({ type: "green", msg: "Reset Link is sent." });
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      err.response?.data?.error?.message &&
        setError({
          type: "red",
          msg: `${err.response?.data?.error?.message}`.trim(),
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.fieldsWrapper}>
      <div className={classes.wrapper}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <AddonInputField
            ref={register}
            name="email"
            // type="email"
            leftIcon={UserIcon}
            placeholder="Email"
            required
            error={!!errors.email}
            errorText={errors.email?.message}
          />
          {loading ? (
            <LoadingWrapper style={{ backgroundColor: "#000" }} />
          ) : (
            <Button
              type="submit"
              style={{ marginTop: "30px", backgroundColor: "#0A0841" }}
            >
              Reset Password
            </Button>
          )}
          {error ? (
            <div
              style={{
                color: error?.type,
                fontWeight: "bold",
                width: "80%",
                display: "flex",
                margin: "auto",
                marginTop: "20px",
              }}
            >
              <p>{error.msg}</p>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
