import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "../../../Utils";

const ButtonTabs = ({ className, activeClass, ...props }) => {
  return (
    <div className={className}>
      <NavLink activeClassName={activeClass} to="/s/login">
        <Button>LOGIN</Button>
      </NavLink>
      <NavLink activeClassName={activeClass} to="/s/signup">
        <Button>SIGN UP</Button>
      </NavLink>
    </div>
  );
};

export default ButtonTabs;
