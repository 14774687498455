import React from "react";
import classes from "./minimalLayout.module.scss";
import Header from "./components/Header";
const Minimal = (props) => {
  const {
    setting: { header = true },
    children,
  } = props;
  return (
    <div className={classes.container}>
      {header && <Header />}
      <div className={classes.contentContaienr}>{children}</div>
    </div>
  );
};

export default Minimal;
