import React, { useState, useEffect } from "react";
import classes from "./status.module.scss";
import { fetchCourtStatus } from "./../../Store/Actions/SettingAction";
import { useDispatch, useSelector } from "react-redux";

const Status = ({ isSidebarOpen }) => {
  const courtStatus = useSelector(
    ({ settingReducer: { courtStatus } }) => courtStatus
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourtStatus());
  }, []);

  return <div className={classes["status"]}>{courtStatus}</div>;
};

export default Status;
