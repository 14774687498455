import React from "react";
import { Link } from "react-router-dom";
import SidebarLayout from "../../../../Utils/SiderbarLayout";
import classes from "./sidebar.module.scss";
import Status from "../../../../Components/Status";
import { useUiSettings } from "../../../../Context/UiContext";

import { ReactComponent as OpinonFeedIcon } from "./../../../../Assets/order.svg";
import { ReactComponent as CourtPersonIcon } from "./../../../../Assets/court-person.svg";
import { ReactComponent as MicPersonIcon } from "./../../../../Assets/mic-person.svg";
import { ReactComponent as BriefcaseIcon } from "./../../../../Assets/briefcase.svg";
import { ReactComponent as PenIcon } from "./../../../../Assets/pen.svg";
import { ReactComponent as SettingIcon } from "./../../../../Assets/cog.svg";
import { ReactComponent as LogoutIcon } from "./../../../../Assets/door-open.svg";
import IconWrapper from "../../../../Components/IconWrapper";
import { useAuthContext } from "../../../../Context/AuthContext";
import momentTimeZone from "moment-timezone";
import useNewTimer from "./../../../../Hooks/useNewTimer";
import { SocialButtons } from "../../../../Components";

const Sidebar = (props) => {
  const { isSidebarOpen, sidebarOpenToggler } = useUiSettings();
  const { logout, authState } = useAuthContext();
  const { userInfo } = authState;

  const clockTimer = useNewTimer(
    momentTimeZone().tz("America/New_York").format("LTS"),
    () => momentTimeZone().tz("America/New_York").format("LTS")
  );
  const logoutHandler = () => {
    sidebarOpenToggler();
    logout();
  };
  return (
    <SidebarLayout>
      <div className={classes.sidebar}>
        <p className={classes["title"]}>Hello,</p>
        <p className={classes["info"]}>
          {`${userInfo?.firstName} ${userInfo?.lastName}`}{" "}
        </p>
        <p>Washington D.C. - United States</p>
        <p>{clockTimer}</p>
        <Status isSidebarOpen={isSidebarOpen} />

        <div className={`${classes["iconWrapper"]} ${classes["space-x"]}`}>
          <Link to="/" onClick={sidebarOpenToggler}>
            <IconWrapper className={classes["i-btn"]} icon={CourtPersonIcon} />
          </Link>
          <Link to="/opinions" onClick={sidebarOpenToggler}>
            <IconWrapper className={classes["i-btn"]} icon={OpinonFeedIcon} />
          </Link>
          <Link to="/feeds" onClick={sidebarOpenToggler}>
            <IconWrapper className={classes["i-btn"]} icon={MicPersonIcon} />
          </Link>
        </div>
        <div className={`${classes["iconWrapper"]} ${classes["space-x"]}`}>
          <Link to="/compose-post" onClick={sidebarOpenToggler}>
            <IconWrapper className={classes["i-btn"]} icon={PenIcon} />
          </Link>
          <Link to="/briefcase" onClick={sidebarOpenToggler}>
            <IconWrapper className={classes["i-btn"]} icon={BriefcaseIcon} />
          </Link>
        </div>
        <div className={`${classes["iconWrapper"]} ${classes["space-x"]}`}>
          <Link to="/change-password" onClick={sidebarOpenToggler}>
            <IconWrapper className={classes["i-bstn"]} icon={SettingIcon} />
          </Link>
          <IconWrapper
            onClick={logoutHandler}
            className={classes["i-bstn"]}
            icon={LogoutIcon}
          />
        </div>

        <div className={classes["bottom-info"]}>
          <SocialButtons />
          <p>
            <Link to="/terms">Terms and Conditions</Link>
            {"  "}
            <Link to="/terms">Privacy Policy</Link>
          </p>
          <p>SCOTUS SPEAKS by Lawtrix</p>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default Sidebar;
