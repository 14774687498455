import React from "react";
import classes from "./publichome.module.scss";

import Logo from "./../../Components/Logo";
import Button from "./../../Utils/Button";
import { Link } from "react-router-dom";

const PublicHome = (props) => {
  return (
    <div className={classes.container}>
      <Logo />
      <p>Voice Your Opinion on the holdings of the U.S. Supreme Court</p>
      <div className={classes.authButtonsWrapper}>
        <Link to="/s/login">
          <Button>login</Button>
        </Link>
        <Link to="/s/signup">
          <Button>sign up</Button>
        </Link>
      </div>
    </div>
  );
};

export default PublicHome;
