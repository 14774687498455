import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// Define you derived routes that use Route with Layout.
import RouteWithLayout from "./Components/RouteWithLayout";
import LoadingWrapper from "./Utils/LoadingWrapper/LoadingWrapper";
import { NotFoundView } from "./Views";
import { useAuthContext } from "./Context/AuthContext";
import {
  routesList,
  publicRoutesId,
  userRoutesId,
  adminRoutesId,
} from "./RoutesComp";

const Routes = () => {
  const { authState } = useAuthContext();
  const { isAuthenticated, userInfo } = authState;
  const userRole = userInfo?.role || "public";

  let derivedRoutes = [];
  switch (userRole) {
    case "admin":
      //   filter out all views that you need
      const adminRoutesId = [1];
      derivedRoutes = routesList.filter(({ id }) => adminRoutesId.includes(id));
      break;

    case "user":
      // const userRoutesId = ["m-1", "m-2", "m-3", "m-4", "m-5", "m-6", "m-7"];
      derivedRoutes = routesList.filter(({ id }) => userRoutesId.includes(id));
      break;

    case "public":
      //   filter out all views that you need

      derivedRoutes = routesList.filter(({ id }) =>
        publicRoutesId.includes(id)
      );
      break;
    default:
      break;
  }
  if (!userInfo) {
    return <LoadingWrapper />;
  }
  return (
    <Switch>
      {/*  */}

      {derivedRoutes.map((route) => {
        return (
          <RouteWithLayout
            key={route.id}
            component={route.component}
            layout={route.layout}
            path={route.path}
            setting={route.setting}
            exact
          />
        );
      })}

      <Route exact path="/">
        <Redirect to={isAuthenticated ? `/ss/${userInfo?.userName}` : "/"} />
      </Route>
      <Route exact path="/s/login">
        <Redirect
          to={isAuthenticated ? `/ss/${userInfo?.userName}` : "/s/login"}
        />
      </Route>

      <Route exact path="/not-found" component={NotFoundView} />
      <Route component={NotFoundView} />
    </Switch>
  );
};

export default Routes;
