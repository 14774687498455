import React, { Fragment, useEffect } from "react";
import { createPortal } from "react-dom";
import classes from "./modal.module.css";
import Backdrop from "./../Backdrop/index";

const ModalTemplate = (props) => {
  const { show, onClose, disableDropClick = false } = props;

  useEffect(() => {
    const getContainerRef = document.getElementById("modal-portal");
    if (!getContainerRef) {
      const makeContainer = document.createElement("div");
      makeContainer.id = "modal-portal";
      document.body.appendChild(makeContainer);
    }
  }, []);
  if (!show || !document.getElementById("modal-portal")) {
    return null;
  }
  return createPortal(
    <Fragment>
      <Backdrop onClose={!disableDropClick ? onClose : () => {}} />
      <div className={classes.container + " styleScroll"}>
        <div className={classes.header}>{props.heading}</div>
        <div className={classes.contentWrapper}>{props.children}</div>
        {!!props.footer && (
          <footer className={`${classes.modal__footer} ${props.footerClass}`}>
            {props.footer}
          </footer>
        )}
      </div>
    </Fragment>,
    document.getElementById("modal-portal")
  );
};
export default ModalTemplate;
