import { SET_SETTING_FIELD_PROPERTY } from "./../Actions/SettingAction";
const initialState = {
  courtStatus: "",
};

const SettingReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case SET_SETTING_FIELD_PROPERTY: {
      return setSettingFieldProperty(state, payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const setSettingFieldProperty = (state, { val, name }) => {
  return {
    ...state,
    [name]: val,
  };
};
export default SettingReducer;
