import React from "react";

const Alert = ({ varient, msg }) => {
  const typesArray = [
    {
      type: "primary",
      color: "#004085",
      backgroundColor: "#cce5ff",
      borderColor: "#b8daff",
    },
    {
      type: "secondary",
      color: "#383d41",
      backgroundColor: "#e2e3e5",
      borderColor: "#d6d8db",
    },
    {
      type: "success",
      color: "#155724",
      backgroundColor: "#d4edda",
      borderColor: "#c3e6cb",
    },
    {
      type: "danger",
      color: "#721c24",
      backgroundColor: "#f8d7da",
      borderColor: "#f5c6cb",
    },
    {
      type: "warning",
      color: "#856404",
      backgroundColor: "#fff3cd",
      borderColor: "#ffeeba",
    },
    {
      type: "info",
      color: "#0c5460",
      backgroundColor: "#d1ecf1",
      borderColor: "#bee5eb",
    },
    {
      type: "light",
      color: "#818182",
      backgroundColor: "#fefefe",
      borderColor: "#fdfdfe",
    },
    {
      type: "dark",
      color: "#1b1e21",
      backgroundColor: "#d6d8d9",
      borderColor: "#c6c8ca",
    },
  ];
  const alertStyle = {
    position: "relative",
    padding: ".75rem 1.25rem",
    marginBottom: " 1rem",
    border: "1px solid transparent",
    borderRadius: ".25rem",
  };
  const alertType = typesArray.find((o) => o.type === varient);

  return <div style={{ ...alertStyle, ...alertType }}>{msg}</div>;
};

export default Alert;
