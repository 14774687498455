import React, { Component } from "react";
import { FacebookIcon, TwitterIcon } from "react-share";
import classes from "./share.module.css";
import { ReactComponent as ShareIcon } from "../../Assets/share-alt.svg";
import { ReactComponent as ClipboardIcon } from "../../Assets/copy.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { toast } from "react-toastify";
import { motion } from "framer";
export class index extends Component {
  state = {
    content: false,
  };
  mouseEnter = (e) => {
    this.setState({
      content: true,
    });
  };
  mouseLeave = (e) => {
    this.setState({
      content: false,
    });
  };
  copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = this.props.url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.info("Post Url Is Copied to Clipboard.");
  };
  render() {
    return (
      <div
        className={classes.container}
        onMouseLeave={this.mouseLeave}
        onMouseEnter={this.mouseEnter}
      >
        <ShareIcon width={25} style={{ margin: "auto" }} />
        <motion.div
          className={classes.btn_div_s}
          initial="close"
          animate={this.state.content ? "open" : "close"}
          variants={{
            open: {
              scale: 1,
            },
            close: {
              scale: 0,
            },
          }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <FacebookShareButton
            url={this.props.url}
            style={{ marginRight: "20px" }}
          >
            <FacebookIcon size={35} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url={this.props.url}
            style={{ marginRight: "20px" }}
          >
            <TwitterIcon size={35} round={true} />
          </TwitterShareButton>

          <div style={{ cursor: "pointer" }} onClick={this.copyToClipboard}>
            <ClipboardIcon width="20px" />
          </div>
        </motion.div>
      </div>
    );
  }
}

export default index;
