import React from "react";
import { Link, useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import classes from "./searchbar.module.scss";
import IconWrapper from "../IconWrapper";
import { ReactComponent as CloseIcon } from "./../../Assets/times.svg";
import { ReactComponent as RightArrow } from "./../../Assets/long-arrow-alt-right.svg";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Index,
  Configure,
  Hits,
  SearchBox,
  Highlight,
} from "react-instantsearch-dom";

import Avatar from "./../Avatar";
import { useLockBodyScroll } from "./../../Hooks/useScrollLock";
import InstitutionRow from "./../InstitutionRow";
import convertDateToSpecificFormat from "./../../Helper/convertDateToSpecificFormat";
const searchClient = algoliasearch(
  "CS148BHL95",
  "3dd1eea79769396f586a49b6219cdacb"
);

const varients = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  isOpen: {
    scale: 1,
    opacity: 1,
  },
  isClosed: {
    scale: 0.2,
    opacity: 0,
  },
};

const SearchBar = ({ isOpen, onClosed, ...restPorps }, ref) => {
  useLockBodyScroll(isOpen);
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={
            classes.searchBarWrapper + " profile-institution-search-wrapper"
          }
          initial="initial"
          variants={varients}
          animate="isOpen"
          exit="isClosed"
          transition={{
            stiffness: 370,
            type: "spring",
            damping: 20,
          }}
        >
          <IconWrapper
            className={classes.closeIcon}
            icon={CloseIcon}
            onClick={onClosed}
          />

          <InstantSearch indexName="USER_DEV" searchClient={searchClient}>
            <h1>SEARCH</h1>

            <SearchBox />

            <Index indexName="USER_DEV">
              <h2>Profiles</h2>
              <Configure hitsPerPage={4} />
              <Hits
                hitComponent={(hitProps) => (
                  <ProfileHit {...hitProps} onClosed={onClosed} />
                )}
              />
            </Index>
            <Index indexName="INSTITUTION_PROD">
              <h2>Institution</h2>
              <Configure hitsPerPage={4} />
              <Hits
                hitComponent={(hitProps) => (
                  <HitItemInstitution {...hitProps} onClosed={onClosed} />
                )}
              />
            </Index>
          </InstantSearch>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

function ProfileHit({ hit, onClosed }) {
  const { profilePicture, userName } = hit;
  const history = useHistory();
  const clickHandler = () => {
    history.push(`/ss/${userName}`);
    onClosed();
  };

  return (
    <div className={classes["hitItem"]} onClick={clickHandler}>
      <Avatar size={52} varient="none" src={profilePicture} />
      <div className={classes["info"]}>
        <p>
          <Highlight attribute="firstName" hit={hit} />{" "}
          <Highlight attribute="lastName" hit={hit} />
        </p>
        <p>
          @<Highlight attribute="userName" hit={hit} />
        </p>
      </div>

      <IconWrapper
        icon={RightArrow}
        styleIcon={{ transform: "rotate(-45deg)" }}
      />
    </div>
  );
}

function HitItemInstitution({ hit, onClosed, onSelectHandler }) {
  const {
    badge,
    profilePicture,
    institutionUsername,
    // objectID,
  } = hit;
  const history = useHistory();
  const clickHandler = () => {
    history.push(`/edu/${institutionUsername}`);
  };
  console.log();
  return (
    <div
      className={classes["hitItem"]}
      onClick={clickHandler}
      style={{ maxHeight: "unset" }}
    >
      <InstitutionRow
        avatarSize={52}
        profilePicture={profilePicture}
        foundedYear={<Highlight attribute="foundedYear" hit={hit} />}
        badge={badge}
        location={<Highlight attribute="location" hit={hit} />}
        state={<Highlight attribute="state" hit={hit} />}
        institutionName={<Highlight attribute="institutionName" hit={hit} />}
        institutionUsername={
          <Highlight attribute="institutionUsername" hit={hit} />
        }
      />
      <span style={{ marginLeft: "auto" }}>
        <IconWrapper
          icon={RightArrow}
          styleIcon={{ transform: "rotate(-45deg)" }}
        />
      </span>
    </div>
  );
}

export default React.forwardRef(SearchBar);
