import React from "react";
import classes from "./../components.module.scss";
import Avatar from "../Avatar";
import convertDateToSpecificFormat from "./../../Helper/convertDateToSpecificFormat";

import ContentLoader from "react-content-loader";
const InstitutionRow = (props) => {
  const { avatarSize = 90 } = props;
  return (
    <div className={classes.institutionRowWrapper}>
      <div className={classes["location-wrapper"]}>
        <Avatar
          src={props.profilePicture}
          size={avatarSize}
          varient={props.badge ? "badge" : "none"}
          badge={props.badge}
        />
        <p>{props.state}</p>
      </div>
      <div className={classes["info"]}>
        <p>{props.institutionName}</p>
        <p>@{props.institutionUsername}</p>
        <p>{props.foundedYear}</p>
        <p>{props.location}</p>
      </div>
    </div>
  );
};
export const InstitutionLoader = (props) => (
  <ContentLoader
    speed={2}
    width={300}
    height={110}
    viewBox="0 0 300 110"
    backgroundColor="#f3f3f3"
    foregroundColor="#dedede"
    {...props}
  >
    <circle cx="45" cy="45" r="45" />
    <rect x="112" y="15" rx="0" ry="0" width="330" height="22" />
    <rect x="112" y="44" rx="0" ry="0" width="199" height="11" />
    <rect x="17" y="97" rx="0" ry="0" width="58" height="87" />
    <rect x="112" y="65" rx="0" ry="0" width="209" height="33" />
  </ContentLoader>
);

export default InstitutionRow;
