import React from "react";
import classes from "./loading.module.scss";

import Spinner from "./../Spinner";
import Logo from "../../Components/Logo";

const LoadingWrapper = () => {
  return (
    <div className={classes.loadingWrapper}>
      <Logo className={classes["logo"]} />
      <Spinner
        style={{ backgroundColor: "#424242", width: "80px", height: "80px" }}
      />
    </div>
  );
};

export default LoadingWrapper;
