const convertDateToSpecificFormat = (date, format) => {
  let newDate = new Date(date);

  const day = newDate.getDate();
  const month = newDate.getUTCMonth() + 1;
  const year = newDate.getUTCFullYear();

  newDate = `${month}/${day}/${year}`;
  if (format === "year") {
    return year;
  }

  if (format === "month") {
    return month;
  }
  if (format === "day") {
    return day;
  }

  return newDate;
};

export default convertDateToSpecificFormat;
