import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../../Context/AuthContext";

import classes from "./../sessions.module.scss";
import AddonInputField from "./../../../Utils/AddonInputField";
import SubmissionButtonsGroup from "./SubmissionButtonsGroup";
import { ReactComponent as UserIcon } from "./../../../Assets/user.svg";
import { ReactComponent as LockIcon } from "./../../../Assets/lock.svg";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    // .matches(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/, "Invalid Email")
    .required("Email must not be empty"),
  password: yup
    .string()
    .min(6, "Password must be 6 character or more")
    .required("Password must not be empty"),
});

const LoginForm = (props) => {
  const { login, setAuthState } = useAuthContext();
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmitHandler = async (data) => {
    try {
      props.setErrorMsg(null);
      props.setLoading(true);
      const resData = await login(data);
      if (resData) {
        props.setLoading(false);
        props.setSuccessMsg("Successfully Logined");
        setTimeout(() => {
          if (props.location.state?.redirectUrl) {
            const url = props.location.state?.redirectUrl;
            props.history.push(url);
          } else {
            // props.history.push(`/ss/${resData.userName}`);
          }
          setAuthState({
            isAuthenticated: true,
            expiresAt: resData.exp,
            userInfo: resData,
            role: resData.role,
          });
        }, 600);
      }
    } catch (err) {
      props.setLoading(false);
      err.response?.data?.error?.message &&
        props.setErrorMsg(`${err.response?.data?.error?.message}`.trim());
    }
  };

  return (
    <div className={classes.fieldsWrapper}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <AddonInputField
          ref={register}
          name="email"
          // type="email"
          leftIcon={UserIcon}
          placeholder="Email"
          required
          error={!!errors.email}
          errorText={errors.email?.message}
        />
        <AddonInputField
          ref={register}
          name="password"
          leftIcon={LockIcon}
          placeholder="Password"
          type="password"
          required
          error={!!errors.password}
          errorText={errors.password?.message}
        />
        <SubmissionButtonsGroup
          submitBtnLabel="LOGIN"
          setLoading={props.setLoading}
          onTwitterClick={props.onTwitterHandler}
        />
      </form>
      <Link to="/forget" className={classes["forget-p"]}>
        Forget Password ?
      </Link>
    </div>
  );
};

export default LoginForm;
