import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import classes from "./feedspost.module.scss";
import PostFeed from "../Post";
import axiosInstance from "./../../axiosInstance";
import Spinner from "../../Utils/Spinner/index";
import Button from "../../Utils/Button";
import useScrollReachToBottom from "../../Hooks/useScrollReachToBottom";

const ProfilePosts = (props) => {
  const [loading, setLoading] = useState(false);
  const [userPostsList, setUserPostsList] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const { userId } = props;

  useEffect(() => {
    fetchUserPosts();
  }, [userId]);

  const fetchUserPosts = async (fetchMore) => {
    if (isEnd || loading) {
      return;
    }
    try {
      setLoading(true);
      let url = `/user/posts/${props.userId}`;
      if (fetchMore) {
        const lastIndex = userPostsList.length - 1;
        url += `/${userPostsList[lastIndex]?.docId}`;
      }
      const res = await axiosInstance.post(url);
      const { data, isEnd } = res.data;

      if (fetchMore) {
        setUserPostsList((p) => [...p, ...data]);
      } else if (!isEnd) {
        setUserPostsList(data);
      }
      isEnd && setIsEnd(true);
    } catch (err) {
      console.log(err, err.response);
    } finally {
      setLoading(false);
    }
  };

  const moreBtnHandler = () => fetchUserPosts(true);
  const onShareClickHandler = ({
    userUid,
    opinionId,
    opinionType,
    personalOpinion,
    docId,
  }) => {
    axiosInstance
      .post(`/user/createPost/${opinionId}`, {
        opinionType,
        personalOpinion,
        sharedFrom: userUid,
        sharedPostId: docId,
      })
      .then((doc) => {
        console.log(doc.data);
        toast.success("Shared");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  // On Scroll reach to bottom fetch more opinions
  useScrollReachToBottom(moreBtnHandler);
  const isShowMoreBtn = userPostsList.length > 0;
  return (
    <Fragment>
      <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
        {!loading && userPostsList?.length === 0 && (
          <h4 style={{ textAlign: "center", textTransform: "uppercase" }}>
            This user has not posted yet
          </h4>
        )}
        {userPostsList.map((_o, i) => (
          <PostFeed key={i} {..._o} />
        ))}
        {loading && (
          <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
        )}

        {isShowMoreBtn && !isEnd && !loading && (
          <div className={classes["more-btn"]}>
            <Button onClick={moreBtnHandler}>MORE</Button>
          </div>
        )}
        {isEnd && <p style={{ textAlign: "center" }}>No More Posts</p>}
      </div>
    </Fragment>
  );
};

export default ProfilePosts;
