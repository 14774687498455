import React from "react";

function useNewTimer(currentDate, ticker) {
  const [date, setDate] = React.useState(currentDate);

  React.useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    if (ticker) {
      setDate(ticker);
    } else {
      setDate(new Date());
    }
  }

  return date;
}

export default useNewTimer;
