import React, { Fragment } from "react";

import Modal from "./../../Utils/Modal";
import Button from "./../../Utils/Button";

const ErrorModal = (props) => {
  return (
    <Modal
      show={!!props.error}
      onClose={props.onClear}
      heading={props.title || "An Error Occurred!"}
      footer={
        <Fragment>
          {props.actionButtons}
          <Button
            style={{
              width: "30%",
              height: "40px",
              marginLeft: "auto",
              marginRight: "16px",
            }}
            onClick={props.onClear}
          >
            Ok
          </Button>
        </Fragment>
      }
    >
      <p style={{ padding: "0.5rem 2rem", fontWeight: "bold", color: "red" }}>
        {props.error}
      </p>
    </Modal>
  );
};

export default ErrorModal;
