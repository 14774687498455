import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./following.module.scss";
import { Button } from "../../Utils";

import Modal from "./../../Utils/Modal/index";
import FollowersList from "./FollowersList";
import { useAuthContext } from "../../Context/AuthContext";

const FollowingUser = () => {
  const {
    authState: { userInfo: followerData },
  } = useAuthContext();
  const [isShowFollowersModal, setIsShowFollowersModal] = useState(false);
  const followerModalHandler = () => {
    setIsShowFollowersModal((_p) => !_p);
  };
  return (
    <Fragment>
      <h4 className={classes["msg-asd"]}>
        Follow Users to Keep Up to Date With Their Personal Opinions Here
      </h4>
      <div
        className={classes["followingWrapper"] + " " + classes["userFollowing"]}
        style={{ marginTop: "4px !important" }}
      >
        <FollowersList
          userName={followerData.userName}
          currentAuthUserId={followerData.userId}
          userId={followerData.userId}
          followerData={followerData}
        />
      </div>
    </Fragment>
  );
};

export default FollowingUser;
