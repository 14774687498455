import React, { useEffect, useState } from "react";
import classes from "./following.module.scss";
import { Button, ButtonSkeleton } from "../../Utils";
import axiosInstance from "./../../axiosInstance";
import { useHistory } from "react-router-dom";
const FollowingUser = ({
  userInfoHandler,
  currentAuthUserId,
  userId,
  userName,
  followerData, // THIS followerData has the details of User that are going to Follow the other use
  styleBtn = null,
}) => {
  const [isFollowedAlready, setIsFollowedAlready] = useState(false);
  const [loading, setloading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    if (currentAuthUserId) {
      (async () => {
        try {
          const res = await axiosInstance.get(`/user/follow/${userId}`);
          const { data } = res.data;
          if (data.isFollowAlready) {
            setIsFollowedAlready(true);
          }
        } catch (err) {
          console.log(err.response);
        } finally {
          setloading(false);
        }
      })();
    } else {
      setloading(false);
    }
  }, [currentAuthUserId]);
  const followHandler = async () => {
    if (currentAuthUserId) {
      try {
        setloading(true);
        const res = await axiosInstance.post(`/user/follow/${userId}`, {
          followerData: {
            userName: followerData.userName,
            firstName: followerData.firstName,
            lastName: followerData.lastName,
            profilePicture: followerData.profilePicture,
            badge: followerData.badge,
            legalPosition: followerData.legalPosition,
          },
        });
        const { data } = res.data;
        if (data.success) {
          setIsFollowedAlready(data.followed);
          // Just call it for updating User follower
          // userInfoHandler && userInfoHandler(userName);
        }
      } catch (err) {
        console.log(err.response);
      } finally {
        setloading(false);
      }
    } else {
      history.push("/s/login", { redirectUrl: `/ss/${userName}` });
    }
  };

  return (
    <div
      className={classes["followingWrapper"] + " " + classes["otherFollowing"]}
    >
      {loading ? (
        <ButtonSkeleton />
      ) : (
        <Button
          className={isFollowedAlready && classes["isFollowed"]}
          onClick={followHandler}
          style={styleBtn}
        >
          {isFollowedAlready ? "Following" : "+ Follow"}
        </Button>
      )}
    </div>
  );
};

export default FollowingUser;
