import {
  FETCH_OPINION_LIST,
  OPINION_IS_END,
  OPINION_LOADING,
  OPINION_FILTER_LOADING,
  OPINION_IS_EMPTY,
  SET_OPINION_DOC_PROPERTY,
  CHANGE_OPINION_FILTER,
} from "./../Actions/OpinionAction";

const initialState = {
  opinionList: [],
  isEmpty: false,
  loading: false,
  isEnd: true,
  filter: "ALL",
  filterLoading: false,
};

const OpinionReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case OPINION_IS_END: {
      return {
        ...state,
        ...payload,
      };
    }
    case OPINION_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case OPINION_FILTER_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case OPINION_IS_EMPTY: {
      return {
        ...state,
        ...payload,
      };
    }
    case FETCH_OPINION_LIST: {
      return {
        ...state,
        opinionList: [...payload.opinionList],
      };
    }
    case CHANGE_OPINION_FILTER: {
      return {
        ...state,
        filter: payload.val || "ALL",
      };
    }
    case SET_OPINION_DOC_PROPERTY: {
      return setDocProperty(state, payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const setDocProperty = (state, { val, name, docId }) => {
  const opinionListClone = [...state.opinionList];

  const findIndexOfDoc = opinionListClone.findIndex((_o) => _o.docId === docId);

  if (findIndexOfDoc >= 0) {
    const docClone = { ...opinionListClone[findIndexOfDoc] };
    docClone[name] = val;

    opinionListClone[findIndexOfDoc] = docClone;

    return {
      ...state,
      opinionList: [...opinionListClone],
    };
  } else {
    return {
      ...state,
    };
  }
};
export default OpinionReducer;
