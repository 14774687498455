import React, { useEffect, useState, Fragment } from "react";
import classes from "./editprofile.module.scss";
import axiosInstance from "./../../axiosInstance";
import { useAuthContext } from "./../../Context/AuthContext";
import { toast } from "react-toastify";
import {
  UpdateUsername,
  IconWrapper,
  UploadAvatar,
  InstitutionSelector,
} from "./../../Components";
import {
  Button,
  Spinner,
  AddonInputField as InputField,
  AlertBox,
} from "../../Utils";
import { ReactComponent as BackArrow } from "./../../Assets/arrow-left.svg";
const profileTypes = ["image/png", "image/jpeg"];

// #EDIT PROFILE COMPONENT
const EditProfile = (props) => {
  const {
    authState: { userInfo },
    getUserInfo,
  } = useAuthContext();
  const [username, setUsername] = useState(userInfo.userName || "");
  const [loading, setLoading] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState({
    code: null,
    msg: "",
  });
  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    lawSchool: "",
    legalPosition: "",
  });
  const [
    showInstitutionSelectorModal,
    setShowInstitutionSelectorModal,
  ] = useState(false);

  useEffect(() => {
    const {
      firstName,
      lastName,
      lawSchool,
      legalPosition,
      institutionName,
      institutionUsername,
    } = userInfo;
    setFormFields({
      firstName: firstName || "",
      lastName: lastName || "",
      lawSchool: lawSchool || "",
      legalPosition: legalPosition || "",
      institutionName: institutionName || "",
      institutionUsername: institutionUsername || "",
    });
  }, []);
  const profileUploadHandler = async (e) => {
    let selected = e.target.files[0];
    if (selected && profileTypes.includes(selected.type)) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("image", selected);
        formData.append("Content-Type", "application/json");

        let API_URL = `/user/uploadPhoto/${userInfo.docId}`;
        if (userInfo.imageFilename) {
          API_URL += `/${userInfo.imageFilename}`;
        }
        const res = await axiosInstance.post(API_URL, formData);

        const { data } = res.data;
        await getUserInfo();
      } catch (err) {
        console.log(err.response);
      } finally {
        setLoading(false);
      }
    }
  };
  const onUsernameChange = (e) => {
    setUsername(e.target.value?.toLowerCase());
  };
  const onUpdateUsernameHandler = async () => {
    try {
      const res = await axiosInstance.post("/user/updateUsername", {
        userName: username,
      });
      await getUserInfo();
    } catch (err) {}
  };

  const onFormChangeHandler = (e) => {
    const { value, name } = e.target;
    setFormFields((p) => ({ ...p, [name]: value }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const reqData = { ...formFields };
      if (userInfo.lawSchool !== reqData.lawSchool) {
        reqData["approvalStatus"] = "pending";
      }
      const res = await axiosInstance.put("/user/edit", { ...reqData });
      // console.log(res);
      // setNotifyMsg({
      //   code: "green",
      //   msg: "Changes Saved Successfully",
      // });
      toast.success("Changes Saved Successfully");
      await getUserInfo();
    } catch (err) {
      setNotifyMsg({
        code: "red",
        msg: err?.response?.data?.error?.message || "There is some error",
      });
    } finally {
      setLoading(false);
    }
  };

  const selectorModalHandler = () => setShowInstitutionSelectorModal((p) => !p);

  const onSelectInstitution = ({
    institutionName,
    institutionUsername,
    id,
  }) => {
    setFormFields((p) => ({
      ...p,
      institutionName,
      institutionUsername,
      lawSchool: id,
    }));
    selectorModalHandler();
  };

  const insMsg = () => {
    const { approvalStatus } = userInfo;
    console.log(approvalStatus);
    if (approvalStatus === "pending") {
      return {
        type: "info",
        msg: `Your Institution Request is pending with ${userInfo.institutionName}.`,
      };
    } else if (approvalStatus === "approved") {
      return {
        type: "success",
        msg: "Your Institution Request is Approved Congratulation!",
      };
    } else if (approvalStatus === "rejected") {
      return {
        type: "danger",
        msg: "Your Institution Request Rejected.",
      };
    } else {
      return {
        type: null,
        msg: "",
      };
    }
  };
  console.log(insMsg());
  return (
    <Fragment>
      {insMsg().type && <AlertBox varient={insMsg().type} msg={insMsg().msg} />}
      <InstitutionSelector
        isOpen={showInstitutionSelectorModal}
        onClosed={selectorModalHandler}
        onSelectHandler={onSelectInstitution}
      />
      <div className={classes.container}>
        <h1>Edit Profile</h1>

        <IconWrapper
          icon={BackArrow}
          className={classes.container__backArrow}
          onClick={() => props.history.push("/")}
        />

        <div className={classes["space-y-6"]}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <UploadAvatar
              src={userInfo.profilePicture}
              loading={loading}
              onImageInputChange={profileUploadHandler}
              defaultFallbackSrc={`https://firebasestorage.googleapis.com/v0/b/scout-speaks.appspot.com/o/default-profile-picture.jpg?alt=media&cache=${new Date().getTime()}`}
            />
          </div>
          <UpdateUsername
            uname={userInfo.userName}
            userName={username}
            onChange={onUsernameChange}
            updateHandler={onUpdateUsernameHandler}
          />

          <form className={classes["space-y-6"]} onSubmit={submitHandler}>
            <InputField
              name="firstName"
              type="text"
              placeholder="First Name"
              required
              value={formFields["firstName"]}
              onChange={onFormChangeHandler}
            />
            <InputField
              name="lastName"
              type="text"
              placeholder="Last Name"
              required
              value={formFields["lastName"]}
              onChange={onFormChangeHandler}
            />
            <InputField
              name="institutionName" //THIS IS THE INSTITUTION ID.
              type="text"
              placeholder="Law School Education"
              required
              onClick={selectorModalHandler}
              value={formFields["institutionName"] || ""}
              onChange={selectorModalHandler}
              // readOnly={true}
            />
            <br></br>
            <label className={classes.dropDown}>
              <span>Select Legal Position</span>
              <select
                name="legalPosition"
                value={formFields["legalPosition"]}
                onChange={onFormChangeHandler}
              >
                <option value="JD Candidate"> JD Candidate</option>
                <option value="LLM Candidate">LLM Candidate</option>
                <option value="Educational Faculty">Educational Faculty</option>
                <option value="Practicing Attorney">Practicing Attorney</option>
              </select>
            </label>

            {loading ? (
              <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
            ) : (
              <Button type="submit" className={classes["submitBtn"]}>
                Save Changes
              </Button>
            )}
            {notifyMsg.code && (
              <h3 style={{ color: notifyMsg.code || "inherit" }}>
                {notifyMsg.msg}
              </h3>
            )}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EditProfile;
