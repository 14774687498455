import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import classes from "./feeds.module.scss";
import PostFeed from "../../Components/Post";
import axios from "../../axiosInstance";
import LoadingWrapper from "../../Utils/Spinner/index";
import useScroll from "../../Hooks/useScrollReachToBottom";
import Suggestions from "../../Components/Suggestions/User";

const FeedsView = (props) => {
  const [postFeed, setFeed] = useState([]);
  const [cache, setCache] = useState([]);
  const [userMarker, setuserMarker] = useState(null);
  const [postMarker, setpostMarker] = useState(null);
  const [loading, setloading] = useState(false);
  const [feedLoading, setFeedLoading] = useState(false);
  const [reiterableCache, setReiterableCache] = useState(false);
  const [suggestions, setSuggestions] = useState(false);
  const firstRender = React.useRef(false);

  const onShareClickHandler = ({
    userUid,
    opinionId,
    opinionType,
    personalOpinion,
    docId,
  }) => {
    console.log(userUid, opinionId, opinionType, personalOpinion, docId);
    axios
      .post(`/user/createPost/${opinionId}`, {
        opinionType,
        personalOpinion,
        sharedFrom: userUid,
        sharedPostId: docId,
      })
      .then((doc) => {
        console.log(doc.data);
        toast.success("Shared");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    setloading(true);
    axios
      .post("/user/feed")
      .then((feed) => {
        const { posts, userLimiter, postLimiter } = feed.data;
        //totalPost ; postLimiter;userId;
        let newCache = [...feed.data.cache].map((val) => {
          let postsTraversed = 0;
          posts.forEach((pos) => {
            if (val.uid === pos.userUid) {
              postsTraversed += 1;
            }
          });
          return { ...val, postsTraversed };
        });
        setFeed(posts);
        setuserMarker(userLimiter);
        setpostMarker(postLimiter);
        setCache((c) => [...c, ...newCache]);
        if (posts.length <= 0) {
          setSuggestions(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);

  useEffect(() => {
    if (firstRender.current === false) {
      firstRender.current = true;
    } else {
      if (cache.length <= 0) {
        console.log("Finished");
        return;
      }
      const slicedUserMeta = cache.slice(0, 2);
      axios
        .post("/user/targetedFeed", { slicedUserMeta })
        .then((feed) => {
          const { posts } = feed.data;
          console.log(feed.data.cache, "_______Backend_______", posts);

          const cacheArrayShallowCopy = [...cache];
          //uid,postMarker
          [...feed.data.cache].forEach((val) => {
            const index = cacheArrayShallowCopy.findIndex(
              (ar) => ar.uid === val.uid
            );
            posts.forEach((pos) => {
              if (val.uid === pos.userUid) {
                cacheArrayShallowCopy[index].postsTraversed += 1;
                cacheArrayShallowCopy[index].postMarker = val.postMarker;
              }
            });
          });
          setFeed((_p) => [..._p, ...posts]);
          setCache(cacheArrayShallowCopy);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setFeedLoading(false);
        });
    }
  }, [reiterableCache]);

  const reIterateFeeds = () => {
    console.log(cache, "In Iterable Feeds");
    const newCache = cache.filter((val) => {
      return val.postsTraversed < val.totalPosts;
    });
    setCache(newCache);
    //
    setReiterableCache((p) => !p);
  };
  const loadMorePosts = () => {
    if (suggestions) {
      return;
    }
    if (userMarker === null) {
      reIterateFeeds();
      return;
    }
    setFeedLoading(true);
    axios
      .post("/user/feed", { postMarker: postMarker, userMarker: userMarker })
      .then((feed) => {
        const { posts, userLimiter, postLimiter } = feed.data;
        console.log(userLimiter);
        let newCache = [...feed.data.cache].map((val) => {
          let postsTraversed = 0;
          posts.forEach((pos) => {
            if (val.uid === pos.userUid) {
              postsTraversed += 1;
            }
          });
          return { ...val, postsTraversed };
        });
        setFeed((_p) => [..._p, ...posts]);
        setuserMarker(userLimiter);
        setpostMarker(postLimiter);
        setCache((c) => [...c, ...newCache]);
        if (userLimiter === null) {
          reIterateFeeds();
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFeedLoading(false);
      });
  };
  useScroll(loadMorePosts);

  const makeFeed = () => {
    const ar = postFeed.map((val, i) => {
      return (
        <PostFeed
          key={i}
          onShareClickHandler={() => onShareClickHandler(val)}
          {...val}
        />
      );
    });
    return ar;
  };
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Feeds</h1>
      <div className={classes.container}>
        <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
          {loading ? (
            <LoadingWrapper style={{ backgroundColor: "var(--gray-light2)" }} />
          ) : suggestions ? (
            <Suggestions />
          ) : (
            makeFeed()
          )}
          {feedLoading ? (
            <LoadingWrapper style={{ backgroundColor: "var(--gray-light2)" }} />
          ) : null}
          <div />
        </div>
      </div>
    </div>
  );
};

export default FeedsView;
