import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import classes from "./postviewer.module.scss";
import { ReactComponent as BackArrow } from "./../../Assets/arrow-left.svg";

import IconWrapper from "./../IconWrapper";
import { useLockBodyScroll } from "../../Hooks/useScrollLock";

const PostViewer = ({ show, children, onBack, header: HeaderComp }) => {
  useEffect(() => {
    const getContainerRef = document.getElementById("post-viewer-portal");
    if (!getContainerRef) {
      const makeContainer = document.createElement("div");
      makeContainer.id = "post-viewer-portal";
      document.body.appendChild(makeContainer);
    }
  }, []);
  useLockBodyScroll(show);
  if (!show || !document.getElementById("post-viewer-portal")) {
    return null;
  }
  return createPortal(
    <div className={classes.postViewer}>
      <HeaderComp />
      <div className={classes.postViewer__header}>
        <IconWrapper
          icon={BackArrow}
          className={classes.postViewer__header__backArrow}
          onClick={onBack}
        />
        <h3>SCOTUS OPINION</h3>
      </div>
      <div className={classes.postViewer__post}>{children}</div>
    </div>,
    document.getElementById("post-viewer-portal")
  );
};

export default PostViewer;
