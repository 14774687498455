import axiosInstance from "./../../axiosInstance";
export const SET_SETTING_FIELD_PROPERTY = "SET_SETTING_FIELD_PROPERTY";

export const GET_COURT_STATUS = "GET_COURT_STATUS";

export const fetchCourtStatus = () => {
  return async (dispatch, getState) => {
    try {
      let url = "/admin/status";
      const res = await axiosInstance.get(url);
      const { text } = res.data;
      dispatch(setSettingFieldPropertyProperty("courtStatus", text || ""));
    } catch (err) {
      console.log(err.response);
      throw err;
    }
  };
};

export const setSettingFieldPropertyProperty = (name, val) => {
  return {
    type: SET_SETTING_FIELD_PROPERTY,
    payload: {
      name,
      val,
    },
  };
};
