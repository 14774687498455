import axios from "axios";

const instance = axios.create({
  baseURL: `${
    process.env.REACT_APP_BUILD_MOD === "prod"
      ? ""
      : "http://localhost:5000/scout-speaks/us-central1"
  }/api/v1`,
  withCredentials: process.env.REACT_APP_BUILD_MOD === "prod" ? false : true,
});

// THIS IS FOR PRODUCTION
// const instance = axios.create({
//   baseURL: `/api/v1`,
// });
instance.defaults.headers["Access-Control-Allow-Credentials"] = true;
instance.interceptors.request.use((config) => {
  config.params = {
    // add your default ones
    nocache: new Date().getTime(),
    // spread the request's params
    ...config.params,
  };
  return config;
});
export default instance;
