import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import classes from "./searchbar.module.scss";
import IconWrapper from "../IconWrapper";
import { ReactComponent as CloseIcon } from "./../../Assets/times.svg";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Index,
  Configure,
  Hits,
  SearchBox,
  Highlight,
} from "react-instantsearch-dom";

import InstitutionRow from "./../InstitutionRow";
import { useLockBodyScroll } from "./../../Hooks/useScrollLock";
const searchClient = algoliasearch(
  "CS148BHL95",
  "3dd1eea79769396f586a49b6219cdacb"
);

const varients = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  isOpen: {
    scale: 1,
    opacity: 1,
  },
  isClosed: {
    scale: 0.2,
    opacity: 0,
  },
};

const SearchBar = ({ isOpen, onClosed, onSelectHandler, ...restPorps }) => {
  useLockBodyScroll(isOpen);
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={
            classes.searchBarWrapper + " profile-institution-search-wrapper "
          }
          initial="initial"
          variants={varients}
          animate="isOpen"
          exit="isClosed"
          transition={{
            stiffness: 370,
            type: "spring",
            damping: 20,
          }}
        >
          <IconWrapper
            className={classes.closeIcon}
            icon={CloseIcon}
            onClick={onClosed}
          />

          <InstantSearch
            indexName="INSTITUTION_PROD"
            searchClient={searchClient}
          >
            <h1>Select Institution</h1>
            <SearchBox />
            <h2>Profiles</h2>
            <Index indexName="INSTITUTION_PROD">
              <Configure hitsPerPage={12} />
              <Hits
                hitComponent={(hitProps) => (
                  <HitItem
                    {...hitProps}
                    onClosed={onClosed}
                    onSelectHandler={onSelectHandler}
                  />
                )}
              />
            </Index>
          </InstantSearch>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

function HitItem({ hit, onClosed, onSelectHandler }) {
  const {
    badge,
    profilePicture,
    founded,
    institutionName,
    institutionUsername,
    objectID,
    state,
    location,
  } = hit;
  const clickHandler = () => {
    onSelectHandler({ institutionName, institutionUsername, id: objectID });
  };
  return (
    <div className={classes["hitItem"]} onClick={clickHandler}>
      <InstitutionRow
        profilePicture={profilePicture}
        founded={founded}
        badge={badge}
        location={<Highlight attribute="location" hit={hit} />}
        state={<Highlight attribute="state" hit={hit} />}
        institutionName={<Highlight attribute="institutionName" hit={hit} />}
        institutionUsername={
          <Highlight attribute="institutionUsername" hit={hit} />
        }
      />
    </div>
  );
}

export default SearchBar;
