import React from "react";
import { Route } from "react-router-dom";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, setting = {}, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout setting={setting}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
