import React from "react";
import classes from "./../sessions.module.scss";
import { Button } from "../../../Utils";

const SubmissionButtonsGroup = ({
  onSubmitClick = () => {},
  onTwitterClick = () => {},
  setLoading,
  submitBtnLabel,
  ...restProps
}) => {
  const onGoogleClick = () => {
    setLoading(true);
  };

  return (
    <div className={classes.submissionButtonsGroup}>
      <Button
        type="submit"
        style={{ backgroundColor: "#0A0841" }}
        onClick={onSubmitClick}
      >
        {submitBtnLabel}
      </Button>
      <Button
        type="button"
        style={{ backgroundColor: "#50ABF8" }}
        onClick={onTwitterClick}
      >
        Twitter
      </Button>
      <Button
        id="google-auth-btn"
        type="button"
        style={{ backgroundColor: "#C70000" }}
        onClick={onGoogleClick}
      >
        Google
      </Button>
    </div>
  );
};

export default SubmissionButtonsGroup;
