import React, { useState, useEffect, Fragment } from "react";

import AddonInputField from "./../../Utils/AddonInputField";
import Button from "./../../Utils/Button/index";
import Spinner from "./../../Utils/Spinner";
import classes from "./user.module.scss";
import { ReactComponent as CheckedIcon } from "./check-solid.svg";
import { ReactComponent as CrossIcon } from "./times.svg";
import axiosInstance from "./../../axiosInstance";

const UpdateUsername = (props) => {
  const [checkRender, setRenders] = useState(0);
  const [isValidUn, setIsValidUn] = useState("first");
  const [searchLoading, setSearchLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const {
    userName,
    onChange,
    uname,
    updateHandler,
    varient,
    setIsValidUsername,
    leftIcon,
  } = props;

  const updated = async () => {
    setSearchLoading(true);
    setIsValidUn("first");
    try {
      await updateHandler();
      setSearchLoading(false);
      setMsg("Username set successfully.");
      setTimeout(() => {
        setMsg(null);
      }, 700);
    } catch (err) {}
  };
  const checkUserNameIsUnique = async (value) => {
    if (checkRender === 0) {
      return null;
    }
    try {
      if (userName.length > 0) {
        if (uname && uname === userName) {
          setIsValidUn("first");
          return;
        }
        setSearchLoading(true);
        const res = await axiosInstance.get(
          `/user/checkUsernameIsValid/${userName}`
        );
        const { data } = res;
        if (data.success) {
          setIsValidUn(data.isValid ? "YES" : "NOT");
          setIsValidUsername && setIsValidUsername(data.isValid);
        }
      } else {
        isValidUn !== "first" && setIsValidUn("NOT");
        isValidUn !== "first" &&
          setIsValidUsername &&
          setIsValidUsername(false);
      }
    } catch (err) {
      console.log(err.response);
    } finally {
      setSearchLoading(false);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (checkRender === 0) {
        setRenders((prev) => prev + 1);
      } else {
        checkUserNameIsUnique(uname);
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [userName]);
  // console.log(userName);
  return (
    <div>
      {/* <h3 style={{ marginBottom: "6px", textAlign: "center" }}>
        Please Set Username{" "}
      </h3> */}
      <AddonInputField
        name="username"
        type="text"
        value={userName}
        onChange={onChange}
        placeholder="Username"
        leftIcon={leftIcon}
        // classNamef={`${classes.field}`}
      />
      {!searchLoading ? (
        <div className={classes.validnameCls} data-valid={isValidUn}>
          {isValidUn === "YES" && (
            <>
              <div className={classes["row-d"]}>
                <p>Valid Username</p>
                <CheckedIcon />
              </div>
              {varient !== "CHECK_VALIDATION" && (
                <Button className={classes.btn} onClick={updated}>
                  Set
                </Button>
              )}
            </>
          )}
          {isValidUn !== "YES" && (
            <>
              <div className={classes["row-d"]}>
                <p>
                  {isValidUn === "first" ? "" : "Username is already taken."}
                </p>
                {isValidUn === "NOT" && <CrossIcon />}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={classes.validnameCls}>
          <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
        </div>
      )}
      {!!msg && <h3 style={{ color: "green", textAlign: "center" }}>{msg}</h3>}
    </div>
  );
};

export default UpdateUsername;
