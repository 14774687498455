import React, { useState, useEffect, Fragment } from "react";
import classes from "./institution.module.scss";
import {
  InstitutionRow,
  ScrollRow,
  ErrorModal,
  InstitutionLoader,
} from "../../Components";

import axiosInstance from "./../../axiosInstance";

const InstitutionView = (props) => {
  const [institutionObj, setInstitutionObj] = useState({
    loading: false,
    msgModal: null,
    profileData: {},
  });

  const {
    params: { instituionSlug },
  } = props.match;
  useEffect(() => {
    //console.log("--img", uname);
    getInstitutionInfo(instituionSlug);
    // return () => {};
  }, [instituionSlug]);

  const getInstitutionInfo = async (username = null) => {
    try {
      if (!username) {
        props.history.push("/not-found");
        return;
      }

      institutionObjProphandler({
        loading: false,
      });
      const res = await axiosInstance.get(`/institution/info/${username}`);
      const { data } = res.data;
      console.log(data);
      institutionObjProphandler({
        profileData: data || {},
        loading: false,
      });
    } catch (err) {
      console.log(err.response);
      institutionObjProphandler({
        profileData: {},
        loading: false,
        msgModal: "Profile Not Found.",
      });
    }
  };

  const clearError = () => {
    institutionObjProphandler({
      profileData: {},
      loading: false,
      msgModal: null,
    });
    props.history.push({
      pathname: "/not-found",
      state: { msg: "Porfile Not Found" },
    });
  };
  const institutionObjProphandler = (prop) => {
    setInstitutionObj((p) => ({
      ...p,
      ...prop,
    }));
  };
  console.log(institutionObj);

  const goToUserProfile = (username) => props.history.push(`/ss/${username}`);
  return (
    <Fragment>
      <ErrorModal error={institutionObj.msgModal} onClear={clearError} />
      <div className={classes.institutionWrapper}>
        {institutionObj.loading ||
        Object.keys(institutionObj.profileData).length < 1 ? (
          <InstitutionLoader />
        ) : (
          <InstitutionRow {...institutionObj.profileData} />
        )}
        <div className={classes["speakers-scrolls-wrapper"]}>
          {institutionObj.profileData?.institutionUid && (
            <ScrollRow
              title="Student Speakers"
              type="student"
              goToUserProfile={goToUserProfile}
              institutionUid={institutionObj.profileData?.institutionUid}
            />
          )}
          {institutionObj.profileData?.institutionUid && (
            <ScrollRow
              title="Faculty Speakers"
              type="faculty"
              goToUserProfile={goToUserProfile}
              institutionUid={institutionObj.profileData?.institutionUid}
            />
          )}
          {institutionObj.profileData?.institutionUid && (
            <ScrollRow
              title="Alumni Speakers"
              type="alumni"
              goToUserProfile={goToUserProfile}
              institutionUid={institutionObj.profileData?.institutionUid}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default InstitutionView;
