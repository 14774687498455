import React from "react";

import Spinner from "./../Spinner";

const FormLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.8)",
        zIndex: 10,
      }}
    >
      <Spinner
        style={{ backgroundColor: "#424242", width: "80px", height: "80px" }}
      />
    </div>
  );
};

export default FormLoader;
