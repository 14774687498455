import React from "react";
import ContentLoader from "react-content-loader";

const ButtonSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={130}
    height={42}
    viewBox="0 0 130 42"
    backgroundColor="#f3f3f3"
    foregroundColor="#dedede"
    {...props}
  >
    <rect x="0" y="1" rx="0" ry="0" width="130" height="42" />
  </ContentLoader>
);

export default ButtonSkeleton;
