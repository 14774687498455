import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./opinion.module.scss";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";

import DatePicker from "react-datepicker";
import OpinionListComp from "./List";

import {
  fetchOpinionList,
  fetchMoreOpinionsList,
  setOpinionFilter,
  setOpinionLoadingFilter,
} from "./../../Store/Actions/OpinionAction";

import { RefreshListButton } from "../../Components";
import { AddonSelectorField } from "../../Utils";
import "react-datepicker/dist/react-datepicker.css";
import convertDateToSpecificFormat from "../../Helper/convertDateToSpecificFormat";
import rangeFunction from "../../Helper/rangeFunction";

const OpinonsView = (props) => {
  const dispatch = useDispatch();
  const {
    isEnd,
    opinionList,
    isEmpty,
    loading,
    filter,
    filterLoading,
  } = useSelector(({ opinionReducer }) => opinionReducer);
  const [filterDate, setFilterDate] = useState("ALL");
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      if (opinionList.length === 0) {
        dispatch(fetchOpinionList());
      }
      isFirstRender.current = false;
    } else {
      dispatch(fetchOpinionList());
    }
  }, []);

  const moreBtnHandler = () => dispatch(fetchMoreOpinionsList());
  // On Scroll reach to bottom fetch more opinions
  useScrollReachToBottom(moreBtnHandler);

  const onChangeYearFilter = (year) => {
    // setFilterDate(date);
    // const year = convertDateToSpecificFormat(date, "year") + 1;
    // dispatch(setOpinionFilter(!date ? "ALL" : year));

    setFilterDate(year);
    dispatch(setOpinionFilter(year === "ALL" ? year : parseInt(year)));
    dispatch(setOpinionLoadingFilter(true));

    dispatch(fetchOpinionList());
  };

  const isShowMoreBtn = opinionList.length > 0;
  return (
    <div className={classes.profileWrapper}>
      <h2 className="text-center">OPINIONS</h2>
      <RefreshListButton onClick={() => dispatch(fetchOpinionList())} />

      <div className={classes.filterWrapper}>
        <AddonSelectorField
          width="220px"
          placeholder="Filter By Term Year"
          value={filterDate}
          onChange={({ target: { value } }) => onChangeYearFilter(value)}
          options={[
            { value: "ALL", text: "All" },
            ...rangeFunction(2014, 2020, 1)
              .map((no) => ({
                value: no,
                text: no,
              }))
              .reverse(),
          ]}
        />

        {/* <DatePicker
          className={classes.filterWrapper__datePicker}
          selected={filterDate}
          onChange={(date) => onChangeYearFilter(date)}
          showYearPicker
          dateFormat="yyyy"
          isClearable
          placeholderText="filter by term year"
          minDate={new Date("1/1/2014")}
          maxDate={new Date()}
        /> */}
      </div>
      <OpinionListComp
        opinionList={opinionList}
        isShowMoreBtn={isShowMoreBtn}
        isEnd={isEnd}
        isEmpty={isEmpty}
        loading={loading}
        filterLoading={filterLoading}
        moreBtnHandler={moreBtnHandler}
      />
    </div>
  );
};

export default OpinonsView;
