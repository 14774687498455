import React, { Component, useEffect } from "react";
import qs from "querystring";
import LoadingWrapper from "../Utils/LoadingWrapper/LoadingWrapper";

import axiosInstance from "./../axiosInstance";

const TwitterAuth = (props) => {
  useEffect(() => {
    (async () => {
      const query = props.location.search.split("?")[1];
      const x = qs.parse(query);
      if (!x.token || !x.secret) {
        props.history.push({
          pathname: "/s/login",
          state: {
            error: "There is some error",
          },
        });
      } else {
        const res = await axiosInstance.post("/user/twitterSignin", {
          accessToken: x.token,
          accessTokenSecret: x.secret,
        });

        if (res.data?.error) {
          console.log(props);
          props.history.push({
            pathname: "/s/login",
            state: {
              error: res.data?.error?.message,
            },
          });
        } else {
          console.log(res);
          const { data } = res.data;
          props.history.push({
            pathname: "/s/login",
            state: {
              twitterAuthSuccess: true,
              resData: data,
            },
          });
        }
      }
    })();
  }, []);

  return <LoadingWrapper />;
};

export default TwitterAuth;
