import React, { useState, useEffect, useLayoutEffect } from "react";

import classes from "./following.module.scss";
import Button from "../../Utils/Button";
import Spinner from "../../Utils/Spinner";
import axiosInstance from "./../../axiosInstance";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";
import UserRow from "./../UserRow/index";
import FollowOtherUser from "./Other";
import { Link } from "react-router-dom";

const FollowersList = (props) => {
  const [loading, setLoading] = useState(false);
  const [followersList, setFollowersList] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  useEffect(() => {
    fetchFollowers();
  }, []);

  const fetchFollowers = async (fetchMore) => {
    if (isEnd || loading) {
      return;
    }
    try {
      setLoading(true);
      let url = "/user/randSuggestions";
      if (fetchMore) {
        const lastIndex = followersList.length - 1;
        console.log(lastIndex, followersList);
        url += `/${followersList[lastIndex]?.userId}`;
      }
      const res = await axiosInstance.get(url);
      const { data, isEnd } = res.data;
      console.log(data);
      if (fetchMore) {
        setFollowersList((p) => [...p, ...data]);
      } else {
        setFollowersList(data);
      }
      isEnd && setIsEnd(true);
    } catch (err) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  };

  const moreBtnHandler = () => fetchFollowers(true);

  // On Scroll reach to bottom fetch more opinions
  //   useScrollReachToBottom(moreBtnHandler);
  const isShowMoreBtn = followersList.length > 0;
  return (
    <div>
      <div className={classes["feeds-container"]}>
        <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
          {followersList.map((followerData, i) => {
            // const { followerData } = _o;
            const _o = followerData;
            return (
              <UserRow
                key={i}
                {...followerData}
                varient="FOLLOWERS"
                varientChild={
                  <FollowOtherUser
                    userName={followerData.userName}
                    userId={_o.userId}
                    currentAuthUserId={props.currentAuthUserId}
                    followerData={props.followerData}
                    styleBtn={{
                      width: "auto",
                      height: "34px",
                      boxShadow: "none",
                      padding: "6px",
                    }}
                  />
                }
              />
            );
          })}
          {loading && (
            <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
          )}

          {isShowMoreBtn && !isEnd && !loading && (
            <div className={classes["more-btn"]}>
              <Button onClick={moreBtnHandler}>MORE</Button>
            </div>
          )}
          {/* {isEnd && <p style={{ textAlign: "center" }}>No More Followers</p>} */}
        </div>
      </div>
    </div>
  );
};

export default FollowersList;
