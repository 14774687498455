import React from "react";
import IconWrapper from "../IconWrapper";
import { ReactComponent as SyncIcon } from "./../../Assets/sync.svg";
const RefreshList = ({ onClick }) => {
  return (
    <div style={{ position: "absolute", right: "8px", top: "12px" }}>
      <IconWrapper icon={SyncIcon} onClick={onClick} />
    </div>
  );
};

export default RefreshList;
