import React from "react";
import { ReactComponent as LogoIcon } from "./../../Assets/logo.svg";

import classes from "./logo.module.scss";
const Logo = ({ className, ...restProps }) => {
  return (
    <div className={classes.logo + " " + className} {...restProps}>
      <LogoIcon />
    </div>
  );
};

export default Logo;
