import React from "react";
import classes from "./avatar.module.scss";

const Avatar = ({
  alt,
  src,
  onClick,
  varient = "badge",
  size = 90,
  name,
  badge,
  ...restProps
}) => {
  const borderWidth = size < 90 ? "2px" : "4px";
  return (
    <div
      className={`${classes.avatarWrapper} `}
      style={{ width: size, height: size, minWidth: size }}
    >
      <div
        className={`${classes.avatar} ${
          varient === "gradient" && "report-circle"
        }`}
        style={{ borderWidth }}
      >
        <img
          src={src}
          alt=""
          loading="lazy"
          width={size}
          height={size}
          className={`${classes.avatarWrapper} ${
            varient === "gradient" && "inner-circle "
          }`}
        />
      </div>
      {name && <p>{name}</p>}
      {varient === "badge" && (
        <img
          src={badge}
          className={classes.badge}
          alt=""
          loading="lazy"
          width={parseInt(size / 2.5)}
          height={parseInt(size / 2.5)}
        />
      )}
    </div>
  );
};

export default Avatar;
