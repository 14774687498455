import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import classes from "./dropshadow.module.scss";
const varients = {
  isOpen: {
    opacity: 1,
  },
  isClosed: {
    opacity: 0,
  },
};
const DropShadow = ({ children, isOpen, onClick }) => {
  return (
    <AnimatePresence>
      <motion.div
        className={classes.dropShadow}
        initial={{ opacity: 0 }}
        variants={varients}
        animate={isOpen}
        // transition={{ type: "tween", duration: 0 }}
        onClick={onClick}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default DropShadow;
