import React, { useState, useEffect, Fragment } from "react";
import classes from "./profile.module.scss";
import ProfileCard from "../../Components/ProfileCard";
import ProfileCardSkeleton from "../../Components/ProfileCard/Skeleton";
import PostFeed from "../../Components/Post";
import ErrorModal from "../../Components/ErrorModal";
import UserProfilePosts from "../../Components/UserProfilePosts";
import axiosInstance from "./../../axiosInstance";
import { useAuthContext } from "../../Context/AuthContext";

// #PROFILE COMPONENTS HERE.
const Profile = (props) => {
  const { authState } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [showMsgModal, setShowMsgModal] = useState(null);
  const {
    params: { uname },
  } = props.match;
  useEffect(() => {
    //console.log("--img", uname);
    getUserInfo(uname);
    // return () => {};
  }, [uname]);

  const getUserInfo = async (username = null) => {
    // console.log("Im called");
    try {
      // const username = props?.match?.params?.uname
      //   ? props?.match?.params?.uname.trim()
      //   : null;
      if (!username) {
        props.history.push("/not-found");
        return;
      }
      const res = await axiosInstance.get(`/user/profile/${username}`);
      const { data } = res.data;
      setProfileData(data);
      setLoading(false);
    } catch (err) {
      setShowMsgModal("Profile Not Found.");
    }
  };

  const clearError = () => {
    setShowMsgModal(null);
    props.history.push({
      pathname: "/not-found",
      state: { msg: "Porfile Not Found" },
    });
  };
  const currentAuthUserId = authState?.userInfo?.userId;
  const isUserAc = profileData?.userId === currentAuthUserId ? true : false;
  return (
    <Fragment>
      <ErrorModal error={showMsgModal} onClear={clearError} />
      <div className={classes.profileWrapper}>
        {loading ? (
          <ProfileCardSkeleton />
        ) : (
          <ProfileCard
            {...profileData}
            isUserAc={isUserAc}
            currentAuthUserId={currentAuthUserId}
            userInfoHandler={getUserInfo}
            followerData={authState?.userInfo || {}}
          />
        )}

        <div className={classes["feeds-container"]}>
          {currentAuthUserId && profileData.userId ? (
            <UserProfilePosts userId={profileData.userId} />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
