import React, { useEffect, useState } from "react";
import classes from "./../components.module.scss";
import { Button } from "../../Utils";
import Avatar from "../Avatar";
import { Scroll, Frame } from "framer";
import axiosInstance from "./../../axiosInstance";

const ScrollRow = ({
  onSeeAllClick,
  title = "Student Speakers",
  type = "student",
  institutionUid,
  goToUserProfile = () => null,
}) => {
  const [speakerObj, setSpeakerObj] = useState({
    loading: false,
    isEmpty: false,
    isEnd: false,
    list: [],
  });

  useEffect(() => {
    institutionUid && getSpeakerList();
  }, [institutionUid]);
  const speakerObjProphandler = (prop) => {
    setSpeakerObj((p) => ({
      ...p,
      ...prop,
    }));
  };

  const getSpeakerList = async (fetchMore) => {
    try {
      speakerObjProphandler({
        loading: true,
      });
      let url = `/institution/public-speakers/${institutionUid}`;
      const lastIndex = speakerObj.list.length - 1;
      if (fetchMore && lastIndex >= 0) {
        url += `/${speakerObj.list[lastIndex]?.docId}`;
      }
      const filterOptions = {
        scope: type,
        approvalStatus: "approved",
      };

      const res = await axiosInstance.post(url, { ...filterOptions });
      const { data, isEnd, isEmpty } = res.data;
      console.log(data);
      if (fetchMore) {
        speakerObjProphandler({
          list: [...speakerObj.list, ...data],
        });
      } else {
        speakerObjProphandler({
          list: [...data],
        });
      }
      speakerObjProphandler({
        isEmpty,
        isEnd,
      });
    } catch (err) {
      console.log(err.response);
      throw err;
    } finally {
      speakerObjProphandler({
        loading: true,
      });
    }
  };

  const fetchMoreSpeakers = () => getSpeakerList(true);

  return (
    <div className={classes.scrollRowWrapper}>
      <div className={classes["titleWrapper"]}>
        <p>{title}</p>
        <div className={classes["divider"]} />
        <Button>See All</Button>
      </div>
      {speakerObj.isEmpty ? (
        <h4>There is No {title} yet</h4>
      ) : (
        <div style={{ position: "relative", height: "120px" }}>
          <Scroll
            direction="horizontal"
            width={"100%"}
            height={"120px"}
            onScrollEnd={fetchMoreSpeakers}
          >
            <div
              className={
                classes["scroller"] + " styleScroll " + classes["space-x"]
              }
            >
              {speakerObj.list.map((obj, i) => (
                <div style={{ width: "90px", height: "90px" }} key={i}>
                  <Frame
                    size={90}
                    onClick={() => goToUserProfile(obj.userName)}
                    backgroundColor="transparent"
                  >
                    <Avatar
                      size={72}
                      src={obj.profilePicture}
                      varient="gradient"
                      name={`${obj.firstName + " " + obj.lastName}`}
                    />
                  </Frame>
                </div>
              ))}
            </div>
          </Scroll>
        </div>
      )}
    </div>
  );
};

export default ScrollRow;
