import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";

import classes from "./briefcase.module.scss";
import PostCard from "../../Components/Post";
import Button from "../../Utils/Button";
import Spinner from "../../Utils/Spinner";
import axiosInstance from "./../../axiosInstance";
import useScrollReachToBottom from "./../../Hooks/useScrollReachToBottom";
import { useAuthContext } from "./../../Context/AuthContext";

import { ReactComponent as BriefcaseIcon } from "./../../Assets/briefcase.svg";

const BirefcaseView = (props) => {
  const {
    authState: { userInfo },
  } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [postsList, setPostsList] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  useEffect(() => {
    fetchBriefcasePosts();
  }, []);

  const fetchBriefcasePosts = async (fetchMore) => {
    if (isEnd || loading) {
      return;
    }
    try {
      setLoading(true);
      let url = "/user/briefcase";
      if (fetchMore) {
        const lastIndex = postsList.length - 1;
        url += `/${postsList[lastIndex]?.docId}`;
      }
      const res = await axiosInstance.get(url);
      const { data, isEnd } = res.data;
      if (fetchMore) {
        setPostsList((p) => [...p, ...data]);
      } else if (!isEnd) {
        setPostsList(data);
      }
      isEnd && setIsEnd(true);
    } catch (err) {
      console.log(err, err.response);
    } finally {
      setLoading(false);
    }
  };

  const moreBtnHandler = () => fetchBriefcasePosts(true);

  // On Scroll reach to bottom fetch more opinions
  useScrollReachToBottom(moreBtnHandler);

  const isShowMoreBtn = postsList.length > 0;

  const renderPostMap = useMemo(() => {
    return postsList.map((_o, i) => <PostCard key={i} {..._o} />);
  }, [postsList]);

  return (
    <div className={classes.wrapper}>
      <h2 className="text-center">{`${userInfo.firstName}'s Briefcase`}</h2>
      <p
        className="text-center"
        style={{
          fontFamily: "Ubuntu",
          margin: "12px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Add Personal Opinions to Your Briefcase by Clicking{" "}
        <BriefcaseIcon style={{ marginLeft: "6px" }} width={22} height={22} />
      </p>
      <div className={classes["feeds-container"]}>
        <div className={classes["space-y"] + " " + classes["feeds-wrapper"]}>
          {renderPostMap}
          {!loading && renderPostMap.length === 0 && (
            <h4 style={{ textAlign: "center" }}>No Post in your briefcase</h4>
          )}
          {loading && (
            <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
          )}
          {isShowMoreBtn && !isEnd && !loading && (
            <div className={classes["more-btn"]}>
              <Button onClick={moreBtnHandler}>MORE</Button>
            </div>
          )}
          {isEnd && <p style={{ textAlign: "center" }}>No More Posts</p>}
        </div>
      </div>
    </div>
  );
};

export default BirefcaseView;
