import React from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar/index";
import classes from "./mainlayout.module.scss";

const Main = (props) => {
  const { children } = props;
  return (
    <div className={classes.mainContainer}>
      <Header />
      <Sidebar />
      <div className={classes.contentContaienr}>{children}</div>
    </div>
  );
};

export default Main;
