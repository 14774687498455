import React from "react";
import ContentLoader from "react-content-loader";

const ProfileSkeleton = (props) => (
  <div style={{ display: "flex", margin: "auto" }}>
    <ContentLoader
      speed={2}
      width={306}
      height={320}
      viewBox="0 0 306 320"
      backgroundColor="#f3f3f3"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="87" y="181" rx="3" ry="3" width="130" height="10" />
      <rect x="6" y="256" rx="0" ry="0" width="130" height="42" />
      <circle cx="151" cy="52" r="46" />
      <rect x="170" y="254" rx="0" ry="0" width="130" height="42" />
      <rect x="61" y="201" rx="3" ry="3" width="178" height="10" />
      <rect x="63" y="159" rx="3" ry="3" width="178" height="10" />
      <rect x="87" y="221" rx="3" ry="3" width="130" height="10" />
      <rect x="86" y="120" rx="3" ry="3" width="130" height="22" />
    </ContentLoader>
  </div>
);

export default ProfileSkeleton;
