import React, { Fragment, useState } from "react";
import classes from "./composepost.module.scss";
import Spinner from "./../../Utils/Spinner";
import { ReactComponent as PenIcon } from "./../../Assets/pen.svg";
import { Link } from "react-router-dom";
import OpinonCard from "../../Components/OpinonCard";
import Button from "./../../Utils/Button";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import axiosInstance from "./../../axiosInstance";
import { toast } from "react-toastify";

const POST_SCHEMA = yup.object().shape({
  opinionType: yup.string().min(4).required("Please Select Opinion Type"),
  personalOpinion: yup.string().required("Your Opinion is required"),
});

// #COMPONENT
const ComposePostView = (props) => {
  const {
    location: { state },
  } = props;
  const [loading, setLoading] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState({
    type: null,
    msg: "",
  });
  const { register, errors, handleSubmit, reset } = useForm({
    resolver: yupResolver(POST_SCHEMA),
  });

  const postSubmitHandler = async (fieldsData) => {
    try {
      setLoading(true);
      const { docId } = state;
      if (!docId || !docId?.length > 0) {
        setNotifyMsg({ type: "red", msg: "Please Select Opinion case again." });
        return;
      }
      const res = await axiosInstance.post(`/user/createPost/${docId}`, {
        ...fieldsData,
      });
      const { data } = res.data;
      if (data.success) {
        toast.success("Opinion Post Composed Successfully.");
        setTimeout(() => {
          props.history.push("/", undefined);
        }, 300);
      }
    } catch (err) {
      setNotifyMsg({ type: "red", msg: "There is some Error" });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  let _render = <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />;
  if (state) {
    _render = (
      <Fragment>
        <p>You selected this Case</p>
        <OpinonCard varient="none" {...state} />
        <div className={classes["pill"]}>
          <Link to="/opinions">Change Case</Link>
        </div>

        <div className={classes["post-form"]}>
          <form onSubmit={handleSubmit(postSubmitHandler)}>
            <label>
              Select Opinion Type
              <select ref={register} name="opinionType">
                <option value="affirm">
                  Affirm (Fully Agree with SCOTUS Holding)
                </option>
                <option value="concurr">
                  Concurr (Partially Agree with SCOTUS Holding)
                </option>
                <option value="dissent">
                  Dissent (Disagree with SCOTUS Holding)
                </option>
              </select>
            </label>
            {errors.opinionType && <p> {errors.opinionType.message}</p>}
            <label>
              Write your opinion
              <textarea ref={register} name="personalOpinion" rows={3} />
              {errors.personalOpinion && (
                <p style={{ color: "red" }}>
                  {" "}
                  {errors.personalOpinion.message}
                </p>
              )}
            </label>
            {loading ? (
              <Spinner style={{ backgroundColor: "var(--gray-light2)" }} />
            ) : (
              <Button type="submit">Publish Personal Opinion</Button>
            )}
          </form>
        </div>
      </Fragment>
    );
  } else if (!state) {
    _render = (
      <Link className={classes["slct-link"]} to="/opinions">
        Click Here to Select a SCOTUS Holding and write your personal opinion.
      </Link>
    );
  }

  return (
    <div className={classes["composePostView-wrapper"]}>
      <h2>
        Compose Post <PenIcon width={32} height={32} />{" "}
      </h2>

      {_render}

      {notifyMsg.type && (
        <h3
          style={{ color: notifyMsg.type, margin: "12px 0px" }}
          className={classes["notifyMsg"]}
        >
          {notifyMsg.msg}
        </h3>
      )}
    </div>
  );
};

export default ComposePostView;
