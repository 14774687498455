import React from "react";
import { createPortal } from "react-dom";
import classes from "./backdrop.module.scss";

const Backdrop = (props) => {
  const { onClose } = props;
  return createPortal(
    <div className={classes.backdrop} onClick={onClose}>
      {props.children}
      {/* Loading... */}
    </div>,
    document.getElementById("backdrop-portal")
  );
};

export default Backdrop;
