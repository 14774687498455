import React, { useEffect } from "react";
import classes from "./profilecard.module.scss";
import Avatar from "./../Avatar";
import FollowingUser from "../Following/User";
import FollowingOther from "../Following/Other";
import followerStringGenerator from "../../Utils/followerStringGenerator";
import { Link } from "react-router-dom";
const ProfileCard = ({
  isUserAc,
  followerCount,
  userName,
  firstName,
  lastName,
  legalPosition = "Legal Position",
  legalSchoolEducation = "Legal School Education",
  profilePicture,
  badge,
  currentAuthUserId,
  userId,
  userInfoHandler,
  followerData,
  lawSchool,
  institutionUsername,
  institutionName,
}) => {
  // console.log(isUserAc, userId, currentAuthUserId);

  const certainFollowerCount = followerStringGenerator(50, followerCount);

  return (
    <div className={classes.profileCardWrapper}>
      <Avatar
        src={profilePicture}
        varient={badge ? "badge" : "none"}
        badge={badge}
      />
      <div className={classes["infoWrapper"]}>
        <h1>{`${firstName} ${lastName}`}</h1>
        <p>@{userName}</p>
        <p>{legalPosition || "Legal Position"}</p>
        {lawSchool && (
          <p>
            <Link
              style={{ color: "var(--blue-d)" }}
              to={`/edu/${institutionUsername}`}
            >
              {institutionName}
            </Link>
          </p>
        )}
        <p>{certainFollowerCount}</p>
      </div>
      {isUserAc && (
        <FollowingUser
          followerCount={followerCount}
          userName={userName}
          currentAuthUserId={currentAuthUserId}
          userId={userId}
          followerData={followerData}
        />
      )}
      {!isUserAc && (
        <FollowingOther
          userName={userName}
          currentAuthUserId={currentAuthUserId}
          userId={userId}
          userInfoHandler={userInfoHandler}
          followerData={followerData}
        />
      )}
    </div>
  );
};

export default ProfileCard;
