import React, { useState } from "react";
import { Link } from "react-router-dom";

import classes from "./../mainlayout.module.scss";
import Logo from "../../../Components/Logo";
import IconWrapper from "../../../Components/IconWrapper";

import { ReactComponent as navIcon } from "./../../../Assets/ic_Home.svg";
import { ReactComponent as searchIcon } from "./../../../Assets/search.svg";

import { useUiSettings } from "./../../../Context/UiContext";
import SearchBar from "../../../Components/SearchBar";
const Header = (props) => {
  const { sidebarOpenToggler } = useUiSettings();

  const [isSearchBarOpen, setSearchBarOpen] = useState(false);

  const searchBarToggler = () => {
    setSearchBarOpen((p) => !p);
    // const error = new Error("This is joke");
    // throw error;
  };

  return (
    <header className={classes.header}>
      <IconWrapper
        style={{ marginLeft: "6px" }}
        icon={navIcon}
        onClick={sidebarOpenToggler}
        className={classes.header__iconWrap}
      />
      <Link to="/feeds">
        <Logo className={classes.logo} />
      </Link>
      <IconWrapper
        style={{ marginRight: "6px" }}
        icon={searchIcon}
        onClick={searchBarToggler}
        className={classes.header__iconWrap}
      />

      <SearchBar isOpen={isSearchBarOpen} onClosed={searchBarToggler} />
    </header>
  );
};

export default Header;
