import React from "react";
import classes from "./uploadAvatar.module.scss";
import { ReactComponent as UploadIcon } from "./../../Assets/arrow-to-top.svg";
import ContentLoader from "react-content-loader";

const Avatar = ({
  alt,
  src = "https://picsum.photos/200",
  varient = "badge",
  size = 90,
  onImageInputChange = () => {},
  loading,
  ...restProps
}) => {
  const borderWidth = size < 90 ? "2px" : "4px";
  return (
    <div
      className={`${classes.avatarWrapper} `}
      style={{ width: size, height: size, minWidth: size }}
    >
      <div
        className={`${classes.avatar} ${
          varient === "gradient" && "report-circle"
        }`}
        style={{ borderWidth }}
      >
        {loading ? (
          <AvatarSkeleton size={size} />
        ) : (
          <img
            src={src}
            alt=""
            loading="lazy"
            width={size}
            height={size}
            className={`${classes.avatarWrapper} ${
              varient === "gradient" && "inner-circle "
            }`}
          />
        )}
        {!loading && varient === "badge" && (
          <label className={classes.badge}>
            <input
              type="file"
              onInput={onImageInputChange}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <UploadIcon />
          </label>
        )}
      </div>
    </div>
  );
};

const AvatarSkeleton = ({ size, ...props }) => (
  <ContentLoader
    speed={2}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#dedede"
    {...props}
  >
    <circle cx={size / 2} cy={size / 2} r={size / 2} />
  </ContentLoader>
);
export default Avatar;
