import React, { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./following.module.scss";
import { Button } from "../../Utils";

import Modal from "./../../Utils/Modal/index";
import FollowersList from "./FollowersList";

const FollowingUser = ({
  followerCount = 0,
  userName,
  currentAuthUserId,
  userId,
  followerData,
}) => {
  const [isShowFollowersModal, setIsShowFollowersModal] = useState(false);
  const followerModalHandler = () => {
    setIsShowFollowersModal((_p) => !_p);
  };
  return (
    <div
      className={classes["followingWrapper"] + " " + classes["userFollowing"]}
    >
      <Link to="/edit-profile">
        <Button>EDIT PROFILE</Button>
      </Link>
      <Button onClick={followerModalHandler}>{followerCount} Followers</Button>
      <Modal
        show={isShowFollowersModal}
        onClose={followerModalHandler}
        heading="Followers"
      >
        <FollowersList
          userName={userName}
          currentAuthUserId={currentAuthUserId}
          userId={userId}
          followerData={followerData}
        />
      </Modal>
    </div>
  );
};

export default FollowingUser;
