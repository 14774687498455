import React from "react";
import classes from "./../components.module.scss";
import Avatar from "../Avatar";
import IconWrapper from "../IconWrapper";
import { ReactComponent as ClockIcon } from "./../../Assets/clock.svg";
import { Link, useHistory } from "react-router-dom";

import convertDateToSpecificFormat from "./../../Helper/convertDateToSpecificFormat";

const UserRow = (props) => {
  const { varient = "NONE", varientChild = null } = props;
  const history = useHistory();
  const gotoProfile = () => {
    history.push(`/ss/${props.userName}`);
  };
  return (
    <div className={classes.userRowWrapper}>
      <Link to={`/ss/${props.userName}`}>
        <Avatar src={props.profilePicture} size={52} varient="none" />
      </Link>
      <div className={classes["info"]} onClick={gotoProfile}>
        <p>
          {props.firstName} {props.lastName}
        </p>
        <p>{props.legalPosition || "Not Given"}</p>
      </div>

      {varient === "NONE" && (
        <div className={classes["time-info"]}>
          <IconWrapper icon={ClockIcon} />
          <p>{convertDateToSpecificFormat(props.createdAt)}</p>
        </div>
      )}
      {varientChild}
    </div>
  );
};

export default UserRow;
