import React from "react";
import classes from "./sidebarlayout.module.scss";
import IconWrapper from "../../Components/IconWrapper";
import { ReactComponent as chevronLeftIcon } from "./../../Assets/chevron-left.svg";
import { motion } from "framer-motion";
import { useUiSettings } from "../../Context/UiContext";

import DropShadow from "./../DropShadow";

const varients = {
  isOpen: {
    x: 0,
    y: 0,
  },
  isClosed: {
    x: "-100%",
    y: 0,
  },
};

const Sidebar = ({ children }) => {
  const { isSidebarOpen, sidebarOpenToggler } = useUiSettings();

  const whichVarient = isSidebarOpen ? "isOpen" : "isClosed";
  return (
    <motion.div
      className={classes.sidebarWrapper}
      initial={{ x: "-100%", y: 0 }}
      variants={varients}
      animate={whichVarient}
      transition={{
        stiffness: 370,
        type: "spring",
        damping: 40,
        when: "beforeChildren",
        staggerDirection: 1,
        staggerChildren: 1,
      }}
    >
      <DropShadow isOpen={whichVarient} onClick={sidebarOpenToggler} />
      <div className={classes.sidebar}>
        <IconWrapper
          icon={chevronLeftIcon}
          className={classes.sidebarTogglerBtn}
          onClick={sidebarOpenToggler}
        />
        {children}
      </div>
    </motion.div>
  );
};

export default Sidebar;
