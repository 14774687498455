import React, { useState, useEffect, useCallback } from "react";
import classes from "./sessions.module.scss";

import ButtonTabs from "./component/ButtonTabs";
import LoginForm from "./component/LoginForm";
import SignupForm from "./component/SignupForm";
import FormLoader from "./../../Utils/FormLoader";
import { useAuthContext } from "../../Context/AuthContext";

const Session = (props) => {
  const { loginWithGoogle, loginWithTwitter, setAuthState } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  //#COMPONENT DID MONT
  useEffect(() => {
    const checkError = props.location?.state?.error;
    if (checkError) {
      setErrorMsg(`${checkError}`.trim());
    }

    if (
      props.location?.state?.twitterAuthSuccess &&
      props.location?.state?.resData
    ) {
      setSuccessMsg("Successfully Logined");

      setTimeout(() => {
        console.log(props.location?.state?.resData);
        props.history.push(`/ss/${props.location?.state?.resData.userName}`);
        setAuthState({
          isAuthenticated: true,
          expiresAt: props.location?.state?.resData.exp,
          userInfo: props.location?.state?.resData,
          role: props.location?.state?.resData.role,
        });
      }, 1000);
    }

    // Google Auth Initializer
    window.gapi.load("auth2", () => {
      window.gapi.auth2
        .init({
          client_id:
            process.env.REACT_APP_BUILD_MOD === "prod"
              ? process.env.REACT_APP_GOOGLE_CLIENTID_PROD
              : process.env.REACT_APP_GOOGLE_CLIENTID_DEV,
          cookiepolicy: "single_host_origin",
          scope: "profile email",
        })
        .then(() => {
          const element = document.getElementById("google-auth-btn");
          const instance = window.gapi.auth2.getAuthInstance();
          instance.attachClickHandler(
            element,
            {},
            onGoogleSuccess,
            onGoogleFailure
          );
        })
        .catch((err) => {
          console.log("error occured", err);
        });
    });
  }, []);

  const onTwitterHandler = async () => {
    try {
      setErrorMsg(null);
      setLoading(true);
      const resData = await loginWithTwitter();
      console.log(resData);
      window.location.href = resData;
      // if (resData) {
      //   setLoading(false);
      //   setSuccessMsg("Successfully Logined");
      //   setAuthState({
      //     isAuthenticated: true,
      //     expiresAt: resData.exp,
      //     userInfo: resData,
      //     role: resData.role,
      //   });
      //   setTimeout(() => {
      //     props.history.push(`/profile/${resData.userName}`);
      //   }, 1000);
      // }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      err.response?.data?.error?.message &&
        setErrorMsg(`${err.response?.data?.error?.message}`.trim());
    }
  };
  const onGoogleSuccess = async (param) => {
    try {
      const token = param.getAuthResponse().id_token;
      setErrorMsg(null);
      setLoading(true);
      const resData = await loginWithGoogle(token);
      console.log(resData);
      if (resData) {
        setLoading(false);
        setSuccessMsg("Successfully Logined");
        setAuthState({
          isAuthenticated: true,
          expiresAt: resData.exp,
          userInfo: resData,
          role: resData.role,
        });
        setTimeout(() => {
          props.history.push(`/ss/${resData.userName}`);
        }, 1000);
      }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      err.response?.data?.error?.message &&
        setErrorMsg(`${err.response?.data?.error?.message}`.trim());
    }
  };
  const onGoogleFailure = (err) => {
    setLoading(false);
    setErrorMsg(`${err.error?.replace(/_/g, " ").toUpperCase()}`);
  };

  // #TAB CHECKER
  let whichTabActive;
  if (props?.match?.path === "/s/login") {
    whichTabActive = "login";
  } else if (props?.match?.path === "/s/signup") {
    whichTabActive = "signup";
  }
  return (
    <div className={classes.container}>
      {/* <Logo /> */}
      <div className={classes.card}>
        <ButtonTabs
          className={classes.buttonTabs}
          activeClass={classes.activeClass}
        />
        <div className={classes.formWrapper}>
          {loading && <FormLoader />}
          {whichTabActive === "login" && (
            <LoginForm
              setLoading={setLoading}
              {...props}
              setErrorMsg={setErrorMsg}
              setSuccessMsg={setSuccessMsg}
              onTwitterHandler={onTwitterHandler}
            />
          )}
          {whichTabActive === "signup" && (
            <SignupForm
              setLoading={setLoading}
              {...props}
              setErrorMsg={setErrorMsg}
              setSuccessMsg={setSuccessMsg}
              onTwitterHandler={onTwitterHandler}
            />
          )}
          {errorMsg && (
            <h3
              style={{ color: "red", margin: "6px 0px", textAlign: "center" }}
            >
              {errorMsg}
            </h3>
          )}
          {successMsg && (
            <h3
              style={{ color: "green", margin: "6px 0px", textAlign: "center" }}
            >
              {successMsg}
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default Session;
