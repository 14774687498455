import React from "react";
import { Link } from "react-router-dom";
import classes from "./NotFound.module.css";

const NotFound = (props) => {
  console.log(props?.location?.state?.msg);
  return (
    <div className={classes.content}>
      <h1>404</h1>
      <p>
        {props?.location?.state?.msg
          ? props?.location?.state?.msg
          : "Page Not Found."}
      </p>
      <Link to="/" className={classes.button}>
        Go back in initial page, is better.
      </Link>
    </div>
  );
};

export default NotFound;
