import { useLayoutEffect } from "react";

export function useLockBodyScroll(conditionally = true) {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    if (conditionally) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalStyle;
    }
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, [conditionally]); // Empty array ensures effect is only run on mount and unmount
}
export const useLockDocScroll = (chk, id) => {
  useLayoutEffect(() => {
    // Get original value of body overflow

    let originalStyle;
    if (chk) {
      originalStyle = window.getComputedStyle(document.getElementById(id))
        .overflow;
      document.getElementById(id).style.overflow = "hidden";
    }

    // Prevent scrolling on mount
    // Re-enable scrolling when component unmounts
    return () => {
      document.getElementById(id).style.overflow = originalStyle;
    };
  }, [chk]); // Empty array ensures effect is only run on mount and unmount
};
